import { Plugin } from '@ckeditor/ckeditor5-core';

import { SimpleFileEditingPlugin } from './SimpleFileEditingPlugin';
import { SimpleFileUiPlugin } from './SimpleFileUiPlugin';

export class SimpleFilePlugin extends Plugin {
  static get requires() {
    return [SimpleFileEditingPlugin, SimpleFileUiPlugin];
  }

  public static get pluginName() {
    return 'SimpleFile' as const;
  }
}
