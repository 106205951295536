import { Editor } from '@ckeditor/ckeditor5-core';

import { dataFileIdAttributeName } from '../simple-file/const';

export function MentionPlugin(editor: Editor) {
  const mentionPlugin = editor.plugins.get('Mention');
  const baseViewParams = {
    name: 'a',
    key: 'data-mention',
    classes: 'mention',
  };

  const model = {
    key: 'mention',
    // @ts-expect-error: TODO: исправить ошибки типизаци и вынести общую логику в отдельный плагин (B2BCORE-10506)
    value: (viewItem) => {
      const userId = viewItem.getAttribute('data-user-id');
      const fileId = viewItem.getAttribute(dataFileIdAttributeName);

      return mentionPlugin.toMentionAttribute(viewItem, {
        link: viewItem.getAttribute('href'),
        userId: viewItem.getAttribute('data-user-id'),
        ...(userId && { userId }),
        ...(fileId && { fileId }),
      });
    },
  };

  // The upcast converter will convert view <a class="mention" href="" data-user-id="">  to the model 'mention' text attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      ...baseViewParams,
      attributes: {
        href: true,
        'data-user-id': true,
      },
    },
    model,
    converterPriority: 'high',
  });

  // The upcast converter will convert view <a class="mention" href="" data-file-id="">  to the model 'mention' text attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      ...baseViewParams,
      attributes: {
        href: true,
        [dataFileIdAttributeName]: true,
      },
    },
    model,
    converterPriority: 'high',
  });

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return;
      }

      // TODO: исправить ошибки типизаци
      // eslint-disable-next-line consistent-return
      return writer.createAttributeElement(
        'a',
        {
          class: 'mention',
          'data-mention': modelAttributeValue.id,
          href: modelAttributeValue.link,
          ...(modelAttributeValue.userId && { 'data-user-id': modelAttributeValue.userId }),
          ...(modelAttributeValue.fileId && { [dataFileIdAttributeName]: modelAttributeValue.fileId }),
        },
        {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid,
        },
      );
    },
    converterPriority: 'high',
  });
}
