import { UiCheckbox, UiCheckboxChangeEvent, notification, usePaddingStyle, useSpace } from '@vkph/ui';
import React, { FC, useState } from 'react';

import { getErrorResponseMessage } from '@vkph/common/utils';

import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<undefined | [boolean, string]>;

export const BooleanCell: FC<Props> = (props) => {
  const { value, dataIndex, fieldOptions, readOnly, onFinish } = props;
  const checked = value === undefined ? Boolean(fieldOptions.default) : value[0];

  const [inputChecked, setInputChecked] = useState(checked);
  const { spaceXL } = useSpace();
  const style = usePaddingStyle([spaceXL]);

  const onChange = (event: UiCheckboxChangeEvent) => {
    const val = event.target.checked;

    setInputChecked(val);

    onFinish({ [dataIndex]: val })?.catch((e) => {
      setInputChecked(!val);
      notification.error({ message: getErrorResponseMessage(e, 'Не удалось изменить значение') });
    });
  };

  return (
    <UiCheckbox style={style} checked={inputChecked} onChange={readOnly ? undefined : onChange}>
      {inputChecked ? fieldOptions.trueValueName : fieldOptions.falseValueName}
    </UiCheckbox>
  );
};
