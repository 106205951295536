import CancelIcon from '@ckeditor/ckeditor5-core/theme/icons/cancel.svg';
import { ButtonView, View } from '@ckeditor/ckeditor5-ui';
import { type Locale, toUnit } from '@ckeditor/ckeditor5-utils';

import './ProgressBarView.scss';
import { FileUploadProgressElement } from '../FileUploadProgress';

const toPx = toUnit('%');

export class ProgressBarView extends View {
  private readonly cancelButton: ButtonView;

  constructor(locale: Locale, fileUploadProgressElement: FileUploadProgressElement) {
    super(locale);

    const bind = this.bindTemplate;

    this.cancelButton = this.createCancelButton(locale);

    // Views define their interface (state) using observable attributes.
    // @ts-expect-error: TODO: исправить ошибки типизации
    this.set('width', 100);
    // @ts-expect-error: TODO: исправить ошибки типизации
    this.set('customWidth', 0);

    this.setTemplate({
      tag: 'div',
      // The element of the view can be defined with its children.
      children: [
        {
          tag: 'div',
          children: [`Загрузка ${fileUploadProgressElement.name}...`],
          attributes: {
            class: ['ck-uploading-progress'],
            style: {
              // @ts-expect-error: TODO: исправить ошибки типизации
              width: bind.to('customWidth', toPx),
            },
          },
        },
        this.cancelButton,
      ],
      attributes: {
        class: [
          'ck-progress-bar',

          // Observable attributes control the state of the view in DOM.
          // @ts-expect-error: TODO: исправить ошибки типизации
          bind.to('elementClass'),
        ],
        style: {
          // @ts-expect-error: TODO: исправить ошибки типизации
          width: bind.to('width', toPx),
        },
      },
    });
  }

  createCancelButton(locale: Locale) {
    const view = new ButtonView(locale);

    view.set({
      label: 'Отменить',
      tooltip: true,
      icon: CancelIcon,
      // @ts-expect-error: TODO: исправить ошибки типизации
      attributes: {
        class: ['ck', 'ck-button', 'ck-off', 'ck-button-cancel', 'ck-uploading-cancel'],
      },
    });

    view.on('execute', () => {
      this.fire('cancel');
    });

    return view;
  }
}
