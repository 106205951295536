// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._84d9a0e0_dynamic-table__dropdown-action-button{margin-left:auto}._84d9a0e0_dynamic-table ._84d9a0e0_dynamic-table__dropdown-action-button{opacity:0}._84d9a0e0_dynamic-table .ant-table-row:hover ._84d9a0e0_dynamic-table__dropdown-action-button{opacity:1}._84d9a0e0_dynamic-table .ant-table-row-indent+.ant-table-row-expand-icon{transform:translateY(24px)}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/dynamic-table/DynamicTable.scss"],"names":[],"mappings":"AAIE,iDACE,gBAAA,CAGF,0EACE,SAAA,CAIA,+FACE,SAAA,CAKF,0EACE,0BAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.dynamic-table {\n  $parent: &;\n\n  &__dropdown-action-button {\n    margin-left: auto;\n  }\n\n  #{$parent}__dropdown-action-button {\n    opacity: 0;\n  }\n\n  :global(.ant-table-row:hover) {\n    #{$parent}__dropdown-action-button {\n      opacity: 1;\n    }\n  }\n\n  :global {\n    .ant-table-row-indent + .ant-table-row-expand-icon {\n      transform: translateY($spaceXL);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dynamic-table__dropdown-action-button": "_84d9a0e0_dynamic-table__dropdown-action-button",
	"dynamicTable__dropdownActionButton": "_84d9a0e0_dynamic-table__dropdown-action-button",
	"dynamic-table": "_84d9a0e0_dynamic-table",
	"dynamicTable": "_84d9a0e0_dynamic-table"
};
export default ___CSS_LOADER_EXPORT___;
