import {
  UiCol,
  UiRow,
  UiIconProps,
  UiTruncateMarkup,
  UiTruncateMarkupProps,
  UiTypography,
  UiTooltipProps,
} from '@vkph/ui';
import React, { FC, PropsWithChildren, CSSProperties } from 'react';

import { formatPhoneNumber, PhoneMask, RegExpPattern } from '@vkph/common/utils';
import ContactWorkPhoneSvg from '@vkph/ui/svg/case.svg';
import ContactMailSvg from '@vkph/ui/svg/contact-mail.svg';
import ContactPhoneSvg from '@vkph/ui/svg/contact-phone.svg';
import ContactLocationSvg from '@vkph/ui/svg/location.svg';
import OfficeSvg from '@vkph/ui/svg/office.svg';
import PrinterOneSvg from '@vkph/ui/svg/printer-one.svg';
import WebsiteSvg from '@vkph/ui/svg/website.svg';

import { ClipboardButton } from '../../clipboard-button';
import { EmployeeContactsLinkIcon } from './icon/EmployeeContactsLinkIcon';

export enum EmployeeContactType {
  MobilePhone = 'mobilePhone',
  Email = 'email',
  WorkPhone = 'workPhone',
  ContactLocation = 'contactLocation',
  Fax = 'fax',
  Website = 'website',
  WorkMode = 'workMode',
}

export type EmployeeContactsType =
  | 'phone'
  | 'email'
  | 'personalEmail'
  | 'address'
  | 'fax'
  | 'site'
  | 'workPhone'
  | 'workAddress'
  | 'workPhoneExtension'
  | 'workMobilePhone'
  | 'mobilePhone'
  | 'workMode';

export const EmployeeContactsLinkIcons: Record<EmployeeContactType, SvgrComponent> = {
  [EmployeeContactType.MobilePhone]: ContactPhoneSvg,
  [EmployeeContactType.Email]: ContactMailSvg,
  [EmployeeContactType.WorkPhone]: ContactWorkPhoneSvg,
  [EmployeeContactType.ContactLocation]: ContactLocationSvg,
  [EmployeeContactType.Fax]: PrinterOneSvg,
  [EmployeeContactType.Website]: WebsiteSvg,
  [EmployeeContactType.WorkMode]: OfficeSvg,
};

export interface EmployeeContactsLinkType extends Partial<Pick<UiTruncateMarkupProps, 'truncate'>> {
  value: string;
  label: string;
  href?: string;
  icon?: UiIconProps['component'];
  showTooltip?: boolean;
  tooltipTitle?: UiTooltipProps['title'];
}

type ContactsLinkItem = (
  value: string,
  options?: Partial<EmployeeContactsLinkType>,
) => EmployeeContactsLinkType;

const getPhoneItem: ContactsLinkItem = (value, options) => ({
  value,
  href: `tel:${value}`,
  label: formatPhoneNumber(value, RegExpPattern.InternationalRu, PhoneMask.InternationalRu),
  icon: EmployeeContactsLinkIcons[EmployeeContactType.WorkPhone],
  ...options,
});

const getAddressItem: ContactsLinkItem = (value, options) => ({
  value,
  label: value,
  icon: EmployeeContactsLinkIcons[EmployeeContactType.ContactLocation],
  truncate: false,
  ...options,
});

const getEmailItem: ContactsLinkItem = (value, options) => ({
  value,
  label: value,
  href: `mailto:${value}`,
  icon: EmployeeContactsLinkIcons[EmployeeContactType.Email],
  ...options,
});

export const employeeContactsLinkTypes: Record<
  EmployeeContactsType,
  (value: string) => EmployeeContactsLinkType
> = {
  phone: getPhoneItem,
  workPhone: (value) => getPhoneItem(value, { tooltipTitle: 'Рабочий телефон' }),
  workPhoneExtension: (value) => getPhoneItem(value, { tooltipTitle: 'Добавочный номер телефона' }),
  workMobilePhone: (value) => getPhoneItem(value, { tooltipTitle: 'Рабочий мобильный телефон' }),
  mobilePhone: (value) =>
    getPhoneItem(value, {
      tooltipTitle: 'Личный мобильный телефон',
      icon: EmployeeContactsLinkIcons[EmployeeContactType.MobilePhone],
    }),
  fax: (value) => ({
    value,
    href: `tel:${value}`,
    label: formatPhoneNumber(value, RegExpPattern.InternationalRu, PhoneMask.InternationalRu),
    icon: EmployeeContactsLinkIcons[EmployeeContactType.Fax],
  }),
  email: (value) => getEmailItem(value, { tooltipTitle: 'Рабочий e-mail' }),
  personalEmail: (value) => getEmailItem(value, { tooltipTitle: 'Персональный e-mail' }),
  site: (value) => ({
    value,
    label: value,
    href: value,
    icon: EmployeeContactsLinkIcons[EmployeeContactType.Website],
  }),
  address: getAddressItem,
  workAddress: (value) => getAddressItem(value, { tooltipTitle: 'Рабочий адрес' }),
  workMode: (value) => ({
    value,
    label: value,
    icon: EmployeeContactsLinkIcons[EmployeeContactType.WorkMode],
    tooltipTitle: 'Режим работы',
  }),
};

type EmployeeContactsLinkComposition = {
  Icon: typeof EmployeeContactsLinkIcon;
};

const labelStyle: CSSProperties = { marginLeft: 8 };

export const EmployeeContactsLink: FC<PropsWithChildren<EmployeeContactsLinkType>> &
  EmployeeContactsLinkComposition = (props) => {
  const { value, icon, href, label, children: image, truncate = true, tooltipTitle, showTooltip } = props;
  const truncatedLabel = <UiTruncateMarkup truncate={truncate}>{label}</UiTruncateMarkup>;

  return (
    <UiRow wrap={false} align="middle" gutter={8}>
      <UiCol flex="auto">
        <UiRow wrap={false}>
          {icon && (
            <EmployeeContactsLinkIcon
              component={icon}
              tooltipTitle={tooltipTitle}
              showTooltip={showTooltip}
            />
          )}
          {!icon && image}
          {href && (
            <UiTypography.Link href={href} style={labelStyle}>
              {truncatedLabel}
            </UiTypography.Link>
          )}
          {!href && (
            <UiTypography.Text strong style={labelStyle}>
              {truncatedLabel}
            </UiTypography.Text>
          )}
        </UiRow>
      </UiCol>
      <UiCol>
        <ClipboardButton value={value} />
      </UiCol>
    </UiRow>
  );
};

EmployeeContactsLink.Icon = EmployeeContactsLinkIcon;
