import { UiFlex, UiForm, UiOptionData, UiSelect, useSpace } from '@vkph/ui';
import React, { FC, useRef } from 'react';

import { MAX_LENGTH_INPUT_250, requiredRuleSelect } from '@vkph/common/utils';

const ENUM_SELECT_MAX_WIDTH = 400;

export const EnumFormFields: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { spaceS } = useSpace();

  return (
    <UiForm.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const choices: string[] = getFieldValue('choices');
        const choiceOptions: UiOptionData[] = choices?.map((option) => ({
          value: option,
          label: option,
        }));

        return (
          <UiFlex vertical ref={ref} gap={spaceS}>
            <UiForm.Item
              name="choices"
              label="Варианты значений"
              extra={`${choices?.length ?? 0}/${MAX_LENGTH_INPUT_250} значений`}
              style={{ marginBottom: 0, width: ENUM_SELECT_MAX_WIDTH, maxWidth: ENUM_SELECT_MAX_WIDTH }}
              rules={[
                {
                  ...requiredRuleSelect,
                  message: 'Укажите хотя бы один вариант',
                },
              ]}
              required
            >
              <UiSelect
                size="large"
                mode="tags"
                placeholder="Добавить вариант"
                getPopupContainer={() => ref.current || document.body}
                maxCount={MAX_LENGTH_INPUT_250}
                maxLength={MAX_LENGTH_INPUT_250}
              />
            </UiForm.Item>
            <UiForm.Item
              name="defaultCellValue"
              label="Данные ячейки для вставки во все ячейки столбца"
              style={{ marginBottom: 0 }}
            >
              <UiSelect
                size="large"
                placeholder="Выбрать вариант"
                options={choiceOptions}
                getPopupContainer={() => ref.current || document.body}
              />
            </UiForm.Item>
          </UiFlex>
        );
      }}
    </UiForm.Item>
  );
};
