import { Command } from '@ckeditor/ckeditor5-core';
import { Notification } from '@ckeditor/ckeditor5-ui';
import { FileLoader, FileRepository, UploadResponse } from '@ckeditor/ckeditor5-upload';
import { toArray, type ArrayOrItem } from '@ckeditor/ckeditor5-utils';

import { FileUploadProgress } from '../../../file-upload-progress';
import type { VideoUtils } from '../videoutils';
import { attributeControlsModelName, attributeFileIdModelName } from './const';

export class UploadVideoCommand extends Command {
  public override refresh(): void {
    const { editor } = this;
    const videoUtils: VideoUtils = editor.plugins.get('VideoUtils');
    const selectedElement = editor.model.document.selection.getSelectedElement()!;

    this.isEnabled = videoUtils.isVideoAllowed() || videoUtils.isVideo(selectedElement);
  }

  public override execute(options: { file: ArrayOrItem<File> }): void {
    const { editor } = this;
    const files = toArray(options.file);
    const fileRepository = editor.plugins.get(FileRepository);

    files.forEach((file) => {
      const loader = fileRepository.createLoader(file);

      if (!loader) {
        return;
      }

      this.readAndUpload(loader, file);
    });
  }

  readAndUpload(loader: FileLoader, file: File) {
    const { editor } = this;
    const { model } = editor;
    const fileRepository = editor.plugins.get(FileRepository);
    const notification = editor.plugins.get(Notification);

    const clean = () => {
      fileRepository.destroyLoader(loader);
    };

    loader
      .read()
      .then(() => {
        FileUploadProgress.createUiBar(editor, {
          uploadId: loader.id,
          name: file.name,
        });
        return loader.upload();
      })
      .then((data) => {
        const { urls, fileId } = data;
        const videoUtils = editor.plugins.get('VideoUtils');

        model.change(() => {
          videoUtils.insertVideo({
            src: (urls as UploadResponse).default,
            [attributeFileIdModelName]: fileId,
            [attributeControlsModelName]: '',
          });

          clean();
        });
      })
      .catch((error) => {
        if (loader.status !== 'error' && loader.status !== 'aborted') {
          throw error;
        }

        if (loader.status === 'error' && error) {
          notification.showWarning(error, {
            title: 'Ошибка загрузки файла',
            namespace: 'upload',
          });
        }

        clean();
      });
  }
}
