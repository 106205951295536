import { UiCard, UiDivider, UiFlex } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { ExternalLinkContact } from '@vkph/common/store/profile/api';
import { ContactLink, UserModel } from '@vkph/common/types/models';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { EmployeeContactsLinkType } from '../contact-link';
import {
  AssistantsContactsList,
  MainContactsList,
  OtherContactsList,
  ExternalLinkIcon,
  SocialLinksList,
} from '../contacts-list';

type Props = {
  mainContacts: EmployeeContactsLinkType[];
  additionalContacts: EmployeeContactsLinkType[];
  otherContacts: ContactLink[];
  socialLinks: (ContactLink | ExternalLinkContact)[];
  messengerIcons?: Map<string, string>;
  externalLinkIcons?: Map<string, ExternalLinkIcon>;
  assistants?: UserModel[];
};

export const ContactsInfoDesktop: FC<PropsWithChildren<Props>> = (props) => {
  const {
    mainContacts,
    otherContacts,
    additionalContacts,
    socialLinks,
    messengerIcons,
    externalLinkIcons,
    children,
    assistants = [],
  } = props;
  const { spaceXL } = useSpace();
  const { spaceS: spaceSpx } = useSpace({ unit: 'px' });
  const cardContentPadding = usePaddingStyle([0, spaceXL]);

  return (
    <>
      <UiCard.Content>
        <UiFlex vertical style={cardContentPadding}>
          {mainContacts.length > 0 && <MainContactsList contactsItems={mainContacts} />}
          {otherContacts.length > 0 && (
            <OtherContactsList contactsItems={otherContacts} messengerIcons={messengerIcons} />
          )}
          {additionalContacts.length > 0 && <MainContactsList contactsItems={additionalContacts} />}
          {socialLinks.length > 0 && (
            <SocialLinksList socialLinksItems={socialLinks} externalLinkIcons={externalLinkIcons} />
          )}
        </UiFlex>

        {assistants.length > 0 && (
          <>
            <UiDivider style={{ margin: `${spaceSpx} 0` }} />
            <UiFlex vertical style={cardContentPadding}>
              <AssistantsContactsList assistants={assistants} />
            </UiFlex>
          </>
        )}
      </UiCard.Content>
      {children}
    </>
  );
};
