import { UploadDraggerArea } from '@vkph/components';
import { UiSpace, UiUploadFile, UiUploadFileList, UiUploadOriginFile } from '@vkph/ui';
import React, { FC, useCallback } from 'react';

import { GetFilesUploadStorage } from '@vkph/common/store';
import { FileUploadAccepts, UploadFile } from '@vkph/common/utils';

type Props = {
  filesUploadStorage: GetFilesUploadStorage;
  preloadedFile: UploadFile<UiUploadFile> | null;
};

export const FileUploadArea: FC<Props> = (props) => {
  const { filesUploadStorage, preloadedFile } = props;

  const { uploadFilesEvent, removeFilesEvent, refetchFileEvent } = filesUploadStorage.storage;

  const uploadFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      const uploadFileList: UploadFile<UiUploadFile>[] = filesToUpload.map((file) => ({
        key: file.uid,
        file,
        fileData: file,
      }));

      uploadFilesEvent({ filesToUpload: uploadFileList, appendData: false });
    },
    [uploadFilesEvent],
  );

  const onPickFiles = useCallback(
    (files: UiUploadOriginFile[]) => {
      uploadFiles(files);
    },
    [uploadFiles],
  );

  const onRemoveFile = useCallback(() => {
    if (preloadedFile) {
      removeFilesEvent([preloadedFile.key]);
    }
  }, [removeFilesEvent, preloadedFile]);

  return (
    <UiSpace direction="vertical" full>
      <UploadDraggerArea
        onPickFiles={onPickFiles}
        compact
        multiple
        accept={FileUploadAccepts.All}
        additionalInfo="Максимальный размер файла: 2 Гб"
      />
      {preloadedFile && (
        <UiUploadFileList
          files={[preloadedFile.fileData]}
          onDelete={onRemoveFile}
          onRefetch={refetchFileEvent}
        />
      )}
    </UiSpace>
  );
};
