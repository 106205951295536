import { CellInput } from '@vkph/components';
import { UiDatePicker, UiFlex, UiForm, UiTimePicker, useModalBase, useSpace } from '@vkph/ui';
import React, { CSSProperties, FC, useEffect } from 'react';

import { getFormattedDate } from '@vkph/common/utils';

import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<string>;

export const DateCell: FC<Props> = (props) => {
  const {
    inputSuffix,
    fieldOptions: { onlyDate },
    value,
    dataIndex,
    readOnly,
    onFinish,
    ...restProps
  } = props;
  const { confirm } = useModalBase();
  const [form] = UiForm.useForm();
  const { spaceM } = useSpace();
  const valueInput = value || null;

  const formItemStyles: CSSProperties = { width: '100%', marginBottom: 0 };
  const datePickerStyles: CSSProperties = { width: '100%' };

  const initialValues = {
    [dataIndex]: valueInput ? new Date(valueInput) : null,
  };

  useEffect(() => {
    form.setFieldValue([dataIndex], valueInput ? new Date(valueInput) : null);
  }, [valueInput]);

  const openModal = () => {
    confirm({
      title: 'Выбрать дату',
      okText: 'Сохранить',
      cancelText: 'Отменить',
      content: (
        <UiForm form={form} onFinish={onFinish} initialValues={initialValues}>
          <UiFlex gap={spaceM}>
            <UiForm.Item name={dataIndex} style={formItemStyles}>
              <UiDatePicker style={datePickerStyles} size="large" />
            </UiForm.Item>
            {!onlyDate && (
              <UiForm.Item name={dataIndex} style={formItemStyles}>
                <UiTimePicker style={datePickerStyles} size="large" />
              </UiForm.Item>
            )}
          </UiFlex>
        </UiForm>
      ),
      onOk: () => {
        form.submit();
      },
      onCancel: () => {
        form.setFieldValue(dataIndex, value ? new Date(value) : '');
      },
    });
  };

  return (
    <UiFlex style={{ position: 'relative' }}>
      <CellInput.Button
        onClick={readOnly ? undefined : openModal}
        {...restProps}
        isEmpty={!valueInput}
        suffix={inputSuffix}
        readOnly={readOnly}
      >
        {valueInput
          ? getFormattedDate(valueInput, onlyDate ? 'dd.MM.yyyy' : 'dd.MM.yyyy в H:mm')
          : 'Добавить дату'}
      </CellInput.Button>
    </UiFlex>
  );
};
