import {
  UiSpinner,
  UiIcon,
  UiButton,
  UiOptionData,
  UiRefSelectProps,
  UiSelect,
  UiSelectProps,
  getExcludedOptions,
  message,
} from '@vkph/ui';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Effect } from 'effector';
import { useStore } from 'effector-react';
import sortBy from 'lodash/sortBy';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { Ordering } from '@vkph/common/types';
import { CreatableSelectEntityStorage } from '@vkph/common/types/creatableMultiselect';
import { CreateDictionaryRecord, RecordResponse } from '@vkph/common/types/models';
import { generateBaseSelectOptions, getErrorResponseMessage } from '@vkph/common/utils';
import AddSvg from '@vkph/ui/svg/add.svg';
import ClearSvg from '@vkph/ui/svg/clear.svg';

import styles from './CreatableMultiSelect.scss';

const CREATE_ID = 'CREATE_ID';
const createDropdownOption = (value: string) => ({
  value,
  label: <span className={styles.creatableMultiSelect__addOption}>{`Добавить «${value}»`}</span>,
  key: CREATE_ID,
});

const generateOnCreateEffectParams = (name: string): CreateDictionaryRecord => ({
  attributes: { name },
});

export enum CreatableMultiSelectOrdering {
  Value = 'value',
  Label = 'label',
}

export interface CreatableMultiSelectProps<OptionType, Params>
  extends Omit<UiSelectProps<OptionType>, 'defaultValue'>,
    Partial<Ordering<CreatableMultiSelectOrdering>> {
  optionsStorage: CreatableSelectEntityStorage;
  createOptionEffect?: Effect<Params, RecordResponse, AxiosError>;
  generateOnCreateEffectParams?: <T = Params>(value: OptionType) => T;
  maxTagItems?: number;
  caseInsensitive?: boolean;
  normalizeSearchValue?: (searchValue: string) => string;
}

export const CreatableMultiSelect: FC<CreatableMultiSelectProps<UiOptionData[], CreateDictionaryRecord>> = (
  props,
) => {
  const {
    value,
    optionsStorage,
    placeholder = 'Добавить',
    createOptionEffect,
    onChange,
    ordering,
    maxTagItems,
    maxLength,
    caseInsensitive,
    normalizeSearchValue = (searchValue) => searchValue,
    ...restProps
  } = props;
  const inputRef = useRef<UiRefSelectProps>(null);
  const [searchText, setSearchText] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const isCreateLoading = createOptionEffect ? useStore(createOptionEffect.pending) : false;
  const selectedOptions = useMemo(() => value || [], [value]);
  const isMaxOptionsReached = useMemo(
    () => Boolean(maxTagItems && selectedOptions.length >= maxTagItems),
    [maxTagItems, selectedOptions],
  );

  const {
    data: fetchedOptions,
    loading: isFetchedOptionsLoading,
    resetStoreEvent,
  } = useAbstractStorage(optionsStorage, {
    autoFetchAndRefetch: searchText.length > 0,
    autoFetchParams: { value: searchText },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const fetchedDropdownOptions = useMemo(() => generateBaseSelectOptions(fetchedOptions), [fetchedOptions]);
  const dropdownOptions = useMemo(() => {
    const excludedOptions = getExcludedOptions(fetchedDropdownOptions, selectedOptions, 'value');

    if (!searchText) {
      return excludedOptions;
    }

    if (isMaxOptionsReached) {
      return undefined;
    }

    if (!createOptionEffect) {
      return excludedOptions;
    }

    const dropdownOptionsSet = new Set(fetchedDropdownOptions.map(({ label }) => label));

    return dropdownOptionsSet.has(searchText)
      ? excludedOptions
      : [createDropdownOption(searchText), ...excludedOptions];
  }, [searchText, fetchedDropdownOptions, selectedOptions, isMaxOptionsReached]);

  const searchHandler = useDebouncedCallback((searchTextRaw: string) => {
    const text = searchTextRaw.trim();

    if (text.length === 0) {
      resetStoreEvent();
    }

    setSearchText(caseInsensitive ? text.toLowerCase() : text);
  }, 400);

  const onSearch = (val: string) => {
    const maxValue = normalizeSearchValue(val).slice(0, maxLength);

    setSearchValue(maxValue);

    searchHandler(maxValue);
  };

  const updateSelectList = useCallback(
    (option: UiOptionData) => {
      const newSelectList = ordering
        ? sortBy([...selectedOptions, option], ordering)
        : [...selectedOptions, option];

      onChange?.(newSelectList, option);
    },
    [selectedOptions],
  );

  const deselectHandler = useCallback(
    (option: UiOptionData) => {
      setSearchText('');
      resetStoreEvent();
      onChange?.(
        selectedOptions.filter(({ value: optionValue }) => optionValue !== option.value),
        option,
      );
    },
    [selectedOptions],
  );

  const selectHandler = (_: UiOptionData, option: UiOptionData) => {
    const { key, value: optionValue } = option;

    if (String(key).startsWith(CREATE_ID)) {
      createOptionEffect?.(generateOnCreateEffectParams(String(optionValue)))
        ?.then((record) => {
          if (record) {
            updateSelectList(generateBaseSelectOptions([record])[0]);
          }

          inputRef?.current?.focus();
        })
        ?.catch((e) => message.error(getErrorResponseMessage(e)));
    } else {
      updateSelectList(option);
    }

    setSearchValue('');
  };

  const AddButtonSuffix = isCreateLoading ? (
    <UiSpinner size="default" spinning />
  ) : (
    <UiButton
      size="middle"
      type="link-secondary"
      icon={<UiIcon component={AddSvg} width={20} height={20} />}
      onClick={() => inputRef.current?.focus()}
    />
  );

  const RemoveButton = (
    <UiButton
      size="middle"
      type="link-secondary"
      icon={<UiIcon component={ClearSvg} width={20} height={20} />}
    />
  );

  const NotFoundContent = isFetchedOptionsLoading && <UiSpinner size="small" spinning />;

  const selectStyles = classNames(styles.creatableMultiSelect, {
    [styles.creatableMultiSelect__select_hide]: isMaxOptionsReached,
  });

  return (
    <UiSelect
      ref={inputRef}
      size="large"
      mode="multiple"
      showSearch={!isMaxOptionsReached}
      labelInValue
      defaultActiveFirstOption
      filterOption={false}
      value={selectedOptions}
      optionLabelProp="label"
      placeholder={placeholder}
      disabled={isCreateLoading}
      removeIcon={RemoveButton}
      suffixIcon={!isMaxOptionsReached && AddButtonSuffix}
      notFoundContent={NotFoundContent}
      className={selectStyles}
      popupClassName={styles.creatableMultiSelect__dropdown}
      {...restProps}
      searchValue={searchValue}
      options={dropdownOptions}
      onDeselect={deselectHandler}
      onSearch={!isMaxOptionsReached ? onSearch : undefined}
      onSelect={selectHandler}
    />
  );
};
