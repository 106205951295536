import { UiButton, UiButtonProps, UiFlex, UiInputProps } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from '../CellInput.scss';

export interface CellInputButtonProps extends UiButtonProps, Pick<UiInputProps, 'suffix'> {
  isEmpty?: boolean;
  isSmallPadding?: boolean;
  readOnly?: boolean;
}

export const CellInputButton: FC<CellInputButtonProps> = (props) => {
  const { suffix, isEmpty, isSmallPadding, readOnly, ...rest } = props;

  return (
    <UiFlex className={styles.uiCellInputContainer}>
      <UiButton
        className={classNames(styles.uiCellInput, styles.uiCellInput__button, {
          [styles.uiCellInput__button_empty]: isEmpty,
          [styles.uiCellInput__button_smallPadding]: isSmallPadding,
          [styles.uiCellInput_readOnly]: readOnly,
        })}
        block
        {...rest}
      />
      {suffix && <span className={styles.uiCellInput__suffix}>{suffix}</span>}
    </UiFlex>
  );
};
