import { UiCheckbox, UiFlex, UiForm, usePaddingStyle, useSpace, useTheme } from '@vkph/ui';
import { useStore } from 'effector-react';
import uniqBy from 'lodash/uniqBy';
import React, { FC, RefObject } from 'react';

import { GetListRowsStorage } from '@vkph/common/store/lists';
import {
  ListCellValues,
  ListColumnFieldType,
  ListColumnModel,
  ListFileResponse,
  ListRowModel,
} from '@vkph/common/types/models';
import { getFormattedDate, getFullNameWithoutPatronymic } from '@vkph/common/utils';
import TableFilterSVG from '@vkph/ui/svg/table-filter.svg';

type FormValues = {
  filters?: string[];
};

type Props = {
  column: ListColumnModel;
  listRowsStorage: GetListRowsStorage;
  containerRef?: RefObject<HTMLDivElement>;
};

const isSupportFilterValue = (value: ListCellValues): value is string | number =>
  typeof value === 'string' || typeof value === 'number';

const isDateType = (fieldType: ListColumnFieldType, value: ListCellValues): value is string =>
  fieldType === ListColumnFieldType.Datetime && typeof value === 'string';

const checkHasFilters = (filters?: string[]) => Array.isArray(filters) && filters.length > 0;

const isFileResponseType = (
  fieldType: ListColumnFieldType,
  value: ListCellValues,
): value is ListFileResponse => {
  return (
    fieldType === ListColumnFieldType.File && typeof value === 'object' && 'id' in value && 'name' in value
  );
};

const normalizeFilterValues = (listRows: ListRowModel[], column: ListColumnModel) => {
  const { id, fieldType, fieldOptions } = column;

  const values = listRows.map((row) => {
    const value = row.rowValues[id] || column.fieldOptions.default;

    if (isFileResponseType(fieldType, value)) {
      return {
        value: value.id,
        label: value.name,
      };
    }

    if (fieldType === ListColumnFieldType.User && typeof value === 'object' && 'id' in value) {
      return {
        value: value.id,
        label: getFullNameWithoutPatronymic(value),
      };
    }

    if (isDateType(fieldType, value)) {
      const dateValue = getFormattedDate(value, fieldOptions.onlyDate ? 'dd.MM.yyyy' : 'dd.MM.yyyy в H:mm');

      return {
        value,
        label: dateValue,
      };
    }

    if (isSupportFilterValue(value)) {
      return {
        value,
        label: value,
      };
    }

    return {
      value: '',
      label: '',
    };
  });

  return uniqBy(values, 'value');
};

export const DynamicTableFilters: FC<Props> = (props) => {
  const { listRowsStorage, containerRef, column } = props;
  const { id } = column;
  const { spaceXS, space2XL, spaceS } = useSpace();
  const [form] = UiForm.useForm<FormValues>();
  const [{ variables: themeVariables }] = useTheme();
  const styles = usePaddingStyle([spaceS, 0]);
  const { padding } = usePaddingStyle([space2XL, space2XL, 0, space2XL]);

  const { data: listRowsData } = useStore(listRowsStorage.storage.store);
  const { updateStoreEvent: updateRowsParamsEvent, store: rowsParamsStore } = listRowsStorage.paramsStore;

  const listRowsParams = useStore(rowsParamsStore);

  const onFinish = (values: FormValues) => {
    const { filters } = values;

    updateRowsParamsEvent({
      ...listRowsParams,
      filters: {
        ...listRowsParams.filters,
        [id]: checkHasFilters(filters) ? filters : undefined,
      },
    });
  };

  return (
    <UiForm form={form} onFinish={onFinish}>
      <UiFlex gap={spaceXS}>
        <UiForm.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <UiForm.Item name="filters" noStyle>
              <UiCheckbox.Menu
                icon={TableFilterSVG}
                getPopupContainer={() => containerRef?.current || document.body}
                overlayInnerStyle={styles}
                placement="bottomLeft"
                align={{ offset: [-spaceXS, -spaceXS] }}
                contentContainerGap={spaceS}
                isActiveRow
                buttonProps={{
                  disabledFocus: false,
                }}
                iconProps={{
                  color: checkHasFilters(getFieldValue('filters'))
                    ? themeVariables.colorBrand
                    : themeVariables.colorIcon,
                }}
                actionsStyles={{ padding, borderTop: themeVariables.spacerBorder }}
                actions={[
                  {
                    type: 'primary',
                    size: 'small',
                    label: 'Применить',
                    onClick: () => {
                      form.submit();
                    },
                  },
                  {
                    type: 'tertiary',
                    size: 'small',
                    label: 'Сбросить',
                    onClick: () => {
                      form.resetFields();
                      form.submit();
                    },
                  },
                ]}
                items={normalizeFilterValues(listRowsData, column)}
              />
            </UiForm.Item>
          )}
        </UiForm.Item>
      </UiFlex>
    </UiForm>
  );
};
