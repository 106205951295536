import { UiFile, UiFileDirection, UiImage, UiSpace, UiVideo } from '@vkph/ui';
import React, { FC, ReactNode, useMemo } from 'react';

import { FileUploadAccepts, getSupportedMediaFile } from '@vkph/common/utils';
import { useTheme } from '@vkph/ui/providers/theme';

import { FileActions } from '../actions/FileActions';
import { BasicFileProps } from '../types';

export const FileColumn: FC<BasicFileProps> = (props) => {
  const { file, actions, subtitle, title, fileName, isFolder, ...fileProps } = props;
  const [{ variables: themeVariables }] = useTheme();

  const isSupportedImageFile = getSupportedMediaFile(FileUploadAccepts.ImageAll, file?.additional);
  const isSupportedVideoFile = getSupportedMediaFile(FileUploadAccepts.VideoAll, file?.additional);

  const thumbnail = useMemo<ReactNode>(() => {
    if (isSupportedVideoFile) {
      return <UiVideo src={file.file} width={162} height={100} />;
    }

    if (isSupportedImageFile) {
      return (
        <UiImage
          placeholderSize={100}
          style={{ backgroundColor: themeVariables.colorBrand16, objectFit: 'contain' }}
          width={162}
          height={100}
          src={file.file}
        />
      );
    }

    return null;
  }, [file]);

  return (
    <UiSpace size={6} direction="vertical" align="center">
      <UiFile
        direction={UiFileDirection.Vertical}
        title={title}
        subtitle={subtitle}
        isFolder={isFolder}
        thumbnail={thumbnail}
        fileName={fileName}
        {...fileProps}
        size="small"
      />
      {actions?.length > 0 && <FileActions actions={actions} />}
    </UiSpace>
  );
};
