import { AvatarSize, UiSkeleton, UiSpace, UiSpaceProps, getNumberSizeAvatar } from '@vkph/ui';
import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';

import { variables } from '@vkph/ui/providers/theme';

import { UiUserInfoProps } from '../../UiUser';

export type UiUserInfoSkeletonProps = {
  className?: UiUserInfoProps['className'];
  size?: AvatarSize;
  style?: CSSProperties;
  align?: UiSpaceProps['align'];
  description?: boolean;
};

const extraSmallSize = parseInt(variables.themeSizes.avatarSizeSmall, 10);

export const UiUserInfoSkeleton: FC<UiUserInfoSkeletonProps> = (props) => {
  const { description = true, style, className, size = 'small', align = 'center' } = props;

  const currentSize = getNumberSizeAvatar(size);

  const skeletonHeight = currentSize <= extraSmallSize ? 12 : 15;

  return (
    <UiSpace style={style} className={classNames(className)} align={align}>
      <UiSkeleton loading circle width={size} height={size} />
      <UiSpace direction="vertical" size={0}>
        <UiSkeleton loading width={200} height={skeletonHeight} style={{ marginBottom: 2 }} />
        {description && <UiSkeleton loading width={130} height={skeletonHeight} />}
      </UiSpace>
    </UiSpace>
  );
};
