import { CellInput, UserAvatar, UserLinkCell } from '@vkph/components';
import {
  UiFlex,
  UiModal,
  UiModalTypes,
  UiSpace,
  UiTruncateMarkup,
  UiTypography,
  usePaddingStyle,
  useSpace,
  useToggle,
} from '@vkph/ui';
import React, { FC } from 'react';

import { UserModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';

import { DynamicTableCellContentProps } from '../types';
import { UserCellModalForm } from './user-cell-form/UserCellForm';

type Props = DynamicTableCellContentProps<UserModel | undefined>;

export const UserCell: FC<Props> = (props) => {
  const { inputSuffix, value, dataIndex, onFinish, readOnly } = props;
  const [isModalOpen, toggleModalOpen] = useToggle([false, true]);
  const { spaceL, spaceXL } = useSpace();
  const padding = usePaddingStyle([spaceXL, spaceL]);

  if (readOnly) {
    return <UiFlex style={padding}>{value && <UserLinkCell size={spaceL} lines={1} user={value} />}</UiFlex>;
  }

  return (
    <>
      <UiModal type={UiModalTypes.Medium} onClose={toggleModalOpen} isOpen={isModalOpen}>
        <UserCellModalForm
          value={value}
          dataIndex={dataIndex}
          onFinish={onFinish}
          onModalClose={() => toggleModalOpen(false)}
        />
      </UiModal>

      <CellInput.Button onClick={() => toggleModalOpen(true)} isEmpty={!value} suffix={inputSuffix}>
        {!value && 'Добавить пользователя'}
        {value && (
          <UiSpace>
            <UserAvatar size={spaceL} src={value.avatar} />
            <UiTypography.Text>
              <UiTruncateMarkup lines={1} truncate>
                {getFullNameWithoutPatronymic(value)}
              </UiTruncateMarkup>
            </UiTypography.Text>
          </UiSpace>
        )}
      </CellInput.Button>
    </>
  );
};
