import { CellInput } from '@vkph/components';
import { UiForm, UiInput, UiPopover, UiTooltip, useToggle } from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import { getMaxLengthRule, requiredRule } from '@vkph/common/utils';

import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<string>;

export const TextCell: FC<Props> = (props) => {
  const { value, containerRef, dataIndex, onFinish, isRequired, fieldOptions, inputSuffix, readOnly } = props;
  const { maxLength } = fieldOptions;

  const [isEdit, setIsEdit] = useToggle([false, true]);
  const [form] = UiForm.useForm();

  const rules = [{ ...(isRequired && requiredRule), ...(maxLength && getMaxLengthRule(maxLength)) }];

  const onSubmit = () => {
    form.submit();
  };

  const onSave = () => {
    form.validateFields().then(() => {
      setIsEdit(false);
      onSubmit();
    });
  };

  const onFocus = () => {
    setIsEdit(true);
  };

  const initialValues = {
    [dataIndex]: value,
  };

  useEffect(() => {
    form.setFieldValue([dataIndex], value);
  }, [value]);

  if (isEdit) {
    return (
      <UiPopover
        content={
          <UiForm form={form} onFinish={onFinish} initialValues={initialValues}>
            <UiForm.Item noStyle name={dataIndex} rules={rules}>
              <UiInput.TextArea
                autoFocus
                onBlur={onSave}
                onPressEnter={onSave}
                style={{ width: '460px' }}
                autoSize={{ minRows: 2, maxRows: 7 }}
                maxLength={maxLength}
              />
            </UiForm.Item>
          </UiForm>
        }
        open={isEdit}
        arrow={false}
        autoAdjustOverflow={false}
        align={{ points: ['tl', 'tl'], offset: [0, 0] }}
        getPopupContainer={() => containerRef?.current || document.body}
        overlayInnerStyle={{ padding: 0 }}
      />
    );
  }

  return (
    <UiTooltip placement="top" title={value}>
      <CellInput
        value={value}
        onFocus={readOnly ? undefined : onFocus}
        placeholder={readOnly ? undefined : 'Добавить текст'}
        suffix={inputSuffix}
        readOnly={readOnly}
      />
    </UiTooltip>
  );
};
