import { UiFlex, UiForm, UiSelect } from '@vkph/ui';
import React, { FC, useRef } from 'react';

import { DictionaryType } from '@vkph/common/types/models';
import { requiredRule } from '@vkph/common/utils';

const selectOptions = [
  {
    label: 'Должность',
    value: DictionaryType.Position,
  },
  {
    label: 'Организации',
    value: DictionaryType.Organization,
  },
  {
    label: 'Подразделения',
    value: DictionaryType.Department,
  },
];

export const DictionaryFormFields: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <UiFlex vertical ref={ref}>
      <UiForm.Item name="dictionaryType" label="Справочники" rules={[requiredRule]} required>
        <UiSelect
          placeholder="Выбрать справочник"
          options={selectOptions}
          getPopupContainer={() => ref.current || document.body}
        />
      </UiForm.Item>
    </UiFlex>
  );
};
