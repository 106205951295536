import { UiInput } from '@vkph/ui';
import cn from 'classnames';
import React, { FC, useState, ChangeEventHandler } from 'react';

import { isValidURL } from '@vkph/common/utils';

import styles from '../CellInput.scss';
import { CellInputProps } from '../types';

export const CellInputLink: FC<CellInputProps> = (props) => {
  const { onChange, value, ...restProps } = props;

  const [inputValue, setInputValue] = useState(value);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
    onChange?.(event);
  };

  const isValid = typeof inputValue === 'string' && isValidURL(inputValue);

  const handleClick = () => {
    if (isValid) {
      window.open(inputValue, '_blank');
    }
  };

  return (
    <UiInput
      {...restProps}
      className={cn(styles.uiCellInput, {
        [styles.uiCellInput__colorLink]: isValid,
      })}
      onClick={handleClick}
      value={value}
      onChange={handleChange}
    />
  );
};
