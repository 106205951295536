import { UiOptionData, UiSelect, UiSelectProps } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from '../CellInput.scss';

export interface CellInputSelectProps extends UiSelectProps<UiOptionData> {
  readOnly?: boolean;
}

export const CellInputSelect: FC<CellInputSelectProps> = (props) => {
  const { readOnly, ...restProps } = props;

  return (
    <UiSelect
      className={classNames(styles.uiCellInput, styles.uiCellInput__select, {
        [styles.uiCellInput_readOnly]: readOnly,
      })}
      {...restProps}
    />
  );
};
