import { FC } from 'react';

import { SurveyFreeAnswerFormatType } from '@vkph/common/types/models';

export enum SurveyAnswerMode {
  Edit = 'Edit',
  Response = 'Response',
}

export enum SurveyMenuSections {
  MULTI_CHOICE_SECTION = 'MULTI_CHOICE_SECTION',
  GRAPHIC_SECTION = 'GRAPHIC_SECTION',
  INPUT_SECTION = 'INPUT_SECTION',
}

export type SpecificAnswerTypeProps = Record<string, unknown>;

export type SurveyQuestionBaseData = {
  value: string;
  icon: SvgrComponent;
  label: string;
  children?: SurveyQuestionBaseData[];
};

export type SurveyQuestionData = SurveyQuestionBaseData & {
  editComponent: FC<SpecificAnswerTypeProps>;
  responseComponent: FC<SpecificAnswerTypeProps>;
};

export type SurveyFreeAnswerFormatData = {
  value: SurveyFreeAnswerFormatType;
  label: string;
};
