import { UiCardProps, UiCard, UiEmpty, UiSkeleton, useBreakpoint } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useMemo, Suspense } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { useSettingsConfig } from '@vkph/common/providers/settings-config';
import { getContactsDictsStorage } from '@vkph/common/store/contacts';
import { ExternalLinkContact } from '@vkph/common/store/profile/api';
import { useProfileStorage } from '@vkph/common/store/profile/hooks';
import { DictMatchTypes } from '@vkph/common/types/api';
import { ContactDictKinds, ContactLink, UserIdModel } from '@vkph/common/types/models';
import { formatPhoneNumber, PhoneMask, RegExpPattern } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { EmployeeContactsLink, employeeContactsLinkTypes } from './contact-link';

const ContactsInfoDesktop = React.lazy(() => import('./desktop/ContactsInfoDesktopModule'));
const ContactsInfoMobile = React.lazy(() => import('./mobile/ContactsInfoMobileModule'));

type EmployeeContactsComponent = {
  Link: typeof EmployeeContactsLink;
};

export type EmployeeContactsProps = {
  userId: UserIdModel;
  style?: UiCardProps['style'];
};

const CONTACTS_MOCK = <UiSkeleton count={5} loading height={20} width="100%" style={{ marginBottom: 8 }} />;

export const formatPhoneNumberRu = (value?: string | null) =>
  formatPhoneNumber(value, RegExpPattern.InternationalRu, PhoneMask.InternationalRu);

export const EmployeeContacts: EmployeeContactsComponent & FC<PropsWithChildren<EmployeeContactsProps>> = (
  props,
) => {
  const { children, style, userId } = props;
  const { config } = useSettingsConfig();
  const {
    data: profileData,
    loading: isProfileLoading,
    error: profileError,
  } = useProfileStorage({ id: userId, withContacts: true });
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceS, spaceXL, spaceM } = useSpace();
  const headerPadding = usePaddingStyle(isLayoutLarge ? [spaceXL] : [spaceS, spaceM, 0]);
  const cardContentPadding = usePaddingStyle(isLayoutLarge ? [0, spaceXL] : [0, spaceM]);

  const {
    messengerDictsStorage,
    messengerDictsOptionsStore,
    externalLinkDictsStorage,
    externalLinkDictsOptionsStore,
  } = useMemo(getContactsDictsStorage, []);

  useAbstractStorage(messengerDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { kind: { type: DictMatchTypes.Exact, value: ContactDictKinds.Messenger } },
  });

  useAbstractStorage(externalLinkDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { kind: { type: DictMatchTypes.Exact, value: ContactDictKinds.ExternalLink } },
  });

  const isUserNotActive = !profileData?.isActive;

  const messengerDictOptions = useStore(messengerDictsOptionsStore);
  const messengerIconByRecordId = useMemo(
    () => new Map(messengerDictOptions.map(({ value: recordId, icon }) => [recordId, icon])),
    [messengerDictOptions],
  );

  const externalLinkDictsOptions = useStore(externalLinkDictsOptionsStore);
  const externalLinkIconByRecordId = useMemo(
    () => new Map(externalLinkDictsOptions.map(({ recordId, icon, prefix }) => [recordId, { icon, prefix }])),
    [externalLinkDictsOptions],
  );

  const {
    workPhone,
    mobilePhone,
    workMobilePhone,
    email,
    workPhoneExtension,
    personalEmail,
    contacts,
    assistants,
    workAddress,
    workMode,
  } = profileData || {};
  const { otherLinks = [], externalLinks = [], otherContacts = [] } = contacts || {};

  const socialLinks = useMemo<Array<ExternalLinkContact | ContactLink>>(
    () => [...externalLinks, ...otherLinks],
    [externalLinks, otherLinks],
  );

  const hasContacts =
    workPhone || workMobilePhone || email || personalEmail || mobilePhone || workAddress || workMode;
  const hasOtherContacts = otherContacts.length > 0;
  const hasSocialLinks = socialLinks.length > 0;
  const hasAssistants = assistants && assistants.length > 0;

  const mainContacts = useMemo(() => {
    const result = [];

    if (mobilePhone) {
      result.push(employeeContactsLinkTypes.mobilePhone(mobilePhone));
    }

    if (workMobilePhone) {
      result.push(employeeContactsLinkTypes.workMobilePhone(workMobilePhone));
    }

    if (workPhone) {
      result.push(employeeContactsLinkTypes.workPhone(workPhone));
    }

    if (config.layouts?.profileWorkPhoneExtension?.value && workPhoneExtension) {
      result.push(employeeContactsLinkTypes.workPhoneExtension(workPhoneExtension));
    }

    if (email) {
      result.push(employeeContactsLinkTypes.email(email));
    }

    if (personalEmail) {
      result.push(employeeContactsLinkTypes.personalEmail(personalEmail));
    }

    return result;
  }, [profileData]);

  const additionalContacts = useMemo(() => {
    const result = [];

    if (workAddress) {
      result.push(employeeContactsLinkTypes.workAddress(workAddress));
    }

    if (workMode) {
      result.push(employeeContactsLinkTypes.workMode(workMode));
    }

    return result;
  }, [profileData]);

  const contactsInfoProps = {
    mainContacts,
    additionalContacts,
    otherContacts,
    socialLinks,
    messengerIcons: messengerIconByRecordId,
    externalLinkIcons: externalLinkIconByRecordId,
    assistants,
  };

  const isEmpty = !hasContacts && !hasOtherContacts && !hasSocialLinks && !hasAssistants && !isProfileLoading;
  const isShowContacts =
    !isUserNotActive && (hasContacts || hasOtherContacts || hasSocialLinks || hasAssistants);

  return (
    <UiCard emptyPadding style={{ paddingBottom: isLayoutLarge ? spaceXL : spaceM, ...style }} size="default">
      <UiCard.Header style={headerPadding}>
        <UiCard.Header.Title>Контакты</UiCard.Header.Title>
      </UiCard.Header>

      <UiCard.Content style={cardContentPadding}>
        {profileError && (
          <UiEmpty.Feed emptyMessage={{ header: 'Ошибка', description: 'Невозможно загрузить контакты' }} />
        )}
        {!isProfileLoading && (isUserNotActive || isEmpty) && (
          <UiEmpty.Feed emptyMessage={{ header: 'Контакты не заполнены' }} />
        )}
        {isProfileLoading && isUserNotActive && CONTACTS_MOCK}
      </UiCard.Content>
      {isShowContacts && (
        <Suspense fallback={CONTACTS_MOCK}>
          {isLayoutLarge && <ContactsInfoDesktop {...contactsInfoProps}>{children}</ContactsInfoDesktop>}
          {!isLayoutLarge && <ContactsInfoMobile {...contactsInfoProps}>{children}</ContactsInfoMobile>}
        </Suspense>
      )}
    </UiCard>
  );
};

EmployeeContacts.Link = EmployeeContactsLink;
