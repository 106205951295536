import {
  UiButton,
  UiButtonProps,
  UiDropdown,
  UiDropdownProps,
  UiIcon,
  ItemType,
  UiMenuClickEventHandler,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, useMemo, BaseSyntheticEvent } from 'react';

import MeatballSvg from '@vkph/ui/svg/meatball.svg';

import styles from './ActionsDropdown.scss';

export type ActionsDropdownItem = {
  label: string;
  onClick?: (params?: unknown) => void;
  icon?: SvgrComponent;
  disabled?: boolean;
};

interface Props extends Omit<UiDropdownProps, 'overlay'> {
  icon?: SvgrComponent;
  items: ActionsDropdownItem[];
  recordId?: string | number;
  disabled?: boolean;
  className?: string;
  itemClassName?: string;
  menuClassName?: string;
  dropdownButtonProps?: UiButtonProps;
}

export const ActionsDropdown: FC<Props> = (props) => {
  const {
    icon = MeatballSvg,
    items,
    recordId,
    disabled = false,
    className,
    itemClassName,
    menuClassName,
    dropdownButtonProps,
    getPopupContainer = () => document.body,
    ...rest
  } = props;

  const eventStopPropagation = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const menuItems = useMemo<ItemType[]>(() => {
    return items.map((item, index) => {
      const onClick: UiMenuClickEventHandler = ({ domEvent }) => {
        eventStopPropagation(domEvent);
        item.onClick?.(recordId);
      };

      return {
        icon: item.icon && (
          <UiIcon
            width={20}
            height={20}
            component={item.icon}
            className={styles.actionsDropdown__iconButton}
          />
        ),
        className: classNames(styles.actionsDropdown__menuItem, itemClassName),
        key: index,
        label: item.label,
        disabled: item.disabled,
        onClick,
      };
    });
  }, [items]);

  return (
    <UiDropdown
      trigger={['hover']}
      disabled={disabled}
      placement="bottomRight"
      getPopupContainer={getPopupContainer}
      menu={{ items: menuItems, className: classNames(styles.actionsDropdown__menu, menuClassName) }}
      {...rest}
    >
      <UiButton
        className={className}
        type="link-secondary"
        disabledFocus
        icon={<UiIcon width={20} height={20} component={icon} />}
        onClick={eventStopPropagation}
        {...dropdownButtonProps}
      />
    </UiDropdown>
  );
};
