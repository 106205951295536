import { UiList } from '@vkph/ui';
import React, { FC } from 'react';

import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { EmployeeContactsLink, EmployeeContactsLinkType } from '../../contact-link';

type Props = {
  contactsItems: EmployeeContactsLinkType[];
};

export const MainContactsList: FC<Props> = (props) => {
  const { contactsItems } = props;

  const { spaceXS } = useSpace();
  const listItemPadding = usePaddingStyle([spaceXS, 0]);

  return (
    <UiList
      split={false}
      grid={{ column: 1 }}
      dataSource={contactsItems}
      renderItem={({ value, label, ...item }) =>
        value &&
        label && (
          <UiList.Item style={{ margin: 0, ...listItemPadding }}>
            <EmployeeContactsLink value={value} label={label} showTooltip {...item} />
          </UiList.Item>
        )
      }
    />
  );
};
