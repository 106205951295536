import { UiTypography, UiIcon, UiIconProps, UiTooltip, UiButtonProps, UiButton } from '@vkph/ui';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo, FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { useQueryParams, useUnmount } from '@vkph/common/hooks';
import { AppearanceDatesActionTypes, appearanceDatesStorage } from '@vkph/common/store/calendar';
import { CalendarViewType } from '@vkph/common/types/models';
import ArrowSvg from '~calendar/components/svg/arrow.svg';
import DaySvg from '~calendar/components/svg/day.svg';
import MonthSvg from '~calendar/components/svg/month.svg';
import WeekSvg from '~calendar/components/svg/week.svg';

import { getMonthWithYearOfWeek } from '../helpers';
import styles from './AppearanceToolbar.scss';

type AppearanceToolbarButton = {
  key: string;
  content?: string;
  component?: UiIconProps['component'];
  modifier?: string;
  type?: UiButtonProps['type'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltipTitle?: string;
};

export const AppearanceToolbar: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const {
    queryParams: { tab },
    setQueryParams,
  } = useQueryParams<{ tab: CalendarViewType }>();
  const { state: locationState } = useLocation();

  const { store: appearanceStore, changeAppearanceDatesEvent, setAppearanceEvent } = appearanceDatesStorage;
  const { currentDate, sinceDate } = useStore(appearanceStore);

  const onChangeTab = (viewType: CalendarViewType) => {
    setQueryParams({ tab: viewType });
  };

  useUnmount(appearanceStore.reset);

  useEffect(() => {
    if (!tab) {
      onChangeTab(CalendarViewType.Week);
    }
  }, []);

  useEffect(() => {
    if (locationState?.date) {
      setAppearanceEvent({ currentDate: parseISO(locationState.date), viewType: tab });
    }
  }, [locationState]);

  useEffect(() => {
    setAppearanceEvent({ viewType: tab });
  }, [tab]);

  const onPrevClickHandler = () => {
    changeAppearanceDatesEvent(AppearanceDatesActionTypes.Prev);
  };

  const onNextClickHandler = () => {
    changeAppearanceDatesEvent(AppearanceDatesActionTypes.Next);
  };

  const onNowClickHandler = () => {
    changeAppearanceDatesEvent(AppearanceDatesActionTypes.Now);
  };

  const viewTypeMap = useMemo(
    () => ({
      [CalendarViewType.Month]: {
        prevTooltip: 'Предыдущий месяц',
        nextTooltip: 'Следующий месяц',
        date: format(currentDate, 'LLLL yyyy', { locale: ru }),
      },
      [CalendarViewType.Week]: {
        prevTooltip: 'Предыдущая неделя',
        nextTooltip: 'Следующая неделя',
        date: getMonthWithYearOfWeek(sinceDate),
      },
      [CalendarViewType.Day]: {
        prevTooltip: 'Предыдущий день',
        nextTooltip: 'Следующий день',
        date: format(currentDate, 'LLLL yyyy', { locale: ru }),
      },
    }),
    [currentDate, sinceDate],
  );

  const buttonsData: AppearanceToolbarButton[] = [
    {
      key: 'day',
      component: DaySvg,
      onClick: () => onChangeTab(CalendarViewType.Day),
      type: tab === CalendarViewType.Day ? 'link' : undefined,
      tooltipTitle: 'День',
    },
    {
      key: 'week',
      component: WeekSvg,
      onClick: () => onChangeTab(CalendarViewType.Week),
      type: tab === CalendarViewType.Week ? 'link' : undefined,
      tooltipTitle: 'Неделя',
    },
    {
      key: 'month',
      component: MonthSvg,
      onClick: () => onChangeTab(CalendarViewType.Month),
      type: tab === CalendarViewType.Month ? 'link' : undefined,
      tooltipTitle: 'Месяц',
    },
    {
      key: 'date',
      content: viewTypeMap[tab]?.date,
    },
    {
      key: 'prev',
      onClick: onPrevClickHandler,
      component: ArrowSvg,
      tooltipTitle: viewTypeMap[tab]?.prevTooltip,
    },
    {
      key: 'next',
      onClick: onNextClickHandler,
      component: ArrowSvg,
      tooltipTitle: viewTypeMap[tab]?.nextTooltip,
      modifier: styles.appearanceToolbar__button_rotateIcon,
    },
    {
      key: 'now',
      content: 'Сегодня',
      modifier: styles.appearanceToolbar__button_today,
      onClick: onNowClickHandler,
      type: 'link',
    },
  ];

  const buttonsEls = buttonsData.map(
    ({ key, content, modifier, onClick, component, type = 'link-secondary', tooltipTitle }) => {
      if (onClick) {
        return (
          <UiTooltip key={key} title={tooltipTitle}>
            <UiButton
              className={classNames(styles.appearanceToolbar__button, modifier)}
              type={type}
              label={content}
              icon={component && <UiIcon component={component} width={20} height={20} />}
              onClick={onClick}
            />
          </UiTooltip>
        );
      }

      return (
        <UiTypography.Title
          key={key}
          level={4}
          style={{ marginBottom: 0 }}
          className={styles.appearanceToolbar__title}
        >
          {content}
        </UiTypography.Title>
      );
    },
  );

  return (
    <div className={styles.appearanceToolbar}>
      <div className={styles.appearanceToolbar__section}>{buttonsEls}</div>
      <div className={styles.appearanceToolbar__section}>{children}</div>
    </div>
  );
};
