import {
  UiButton,
  UiForm,
  UiModal,
  UiModalTypes,
  UiUploadFile,
  getModalStepsForSingleTitle,
  usePaddingStyle,
  useSpace,
} from '@vkph/ui';
import React, { FC } from 'react';

import { GetFilesUploadStorage } from '@vkph/common/store';
import { UploadFile } from '@vkph/common/utils';

import { FileUploadArea } from '../file-upload-area/FileUploadArea';

type Props = {
  isOpen: boolean;
  filesUploadStorage: GetFilesUploadStorage;
  parentId: string;
  preloadedFile: UploadFile<UiUploadFile> | null;
  onLoadFileToStorage: () => void;
  closeModal: () => void;
};

export const FileCellUploadForm: FC<Props> = (props) => {
  const { isOpen, filesUploadStorage, preloadedFile, onLoadFileToStorage, closeModal } = props;
  const [form] = UiForm.useForm();
  const { spaceM, spaceXL } = useSpace();
  const styles = usePaddingStyle([spaceM, spaceXL]);

  return (
    <UiModal type={UiModalTypes.Medium} isOpen={isOpen} onClose={closeModal}>
      <UiForm form={form} onFinish={onLoadFileToStorage}>
        <UiModal.Header>
          <UiModal.Header.Title steps={getModalStepsForSingleTitle('Загрузка файлов')} />
        </UiModal.Header>
        <UiModal.Content style={styles}>
          <FileUploadArea filesUploadStorage={filesUploadStorage} preloadedFile={preloadedFile} />
        </UiModal.Content>
        <UiModal.Footer hasTopBorder>
          <UiModal.Footer.Buttons>
            <UiButton
              onClick={form.submit}
              disabled={!preloadedFile}
              label="Сохранить"
              size="large"
              type="primary"
            />
            <UiButton onClick={closeModal} label="Отмена" size="large" type="tertiary" />
          </UiModal.Footer.Buttons>
        </UiModal.Footer>
      </UiForm>
    </UiModal>
  );
};
