import { UiOverlay, UiOverlayTypes, UiRow, UiCol, UiSpace, UiIcon, UiImage } from '@vkph/ui';
import React, { CSSProperties, FC } from 'react';

import { useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import CommentSvg from '@vkph/ui/svg/comment.svg';
import LikeSvg from '@vkph/ui/svg/like.svg';

import { PreparedImage } from '../helpers';

type Props = {
  gap: number;
  image: PreparedImage;
  onClick: (image: PreparedImage) => void;
};

export const ImageGridItem: FC<Props> = (props) => {
  const { gap, image, onClick } = props;
  const { src, reactionsCount, commentsCount } = image;
  const { spaceXL, spaceS } = useSpace();

  const defaultImageStyle: CSSProperties = {
    width: image.width,
    height: image.height,
  };

  const imageContainerStyle: CSSProperties = {
    ...defaultImageStyle,
    margin: `0 ${gap}px`,
    display: 'inline-block',
    position: 'relative',
  };

  const onKeyPress: React.KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      onClick(image);
    }
  };

  return (
    <div
      role="link"
      tabIndex={-1}
      onClick={() => onClick(image)}
      onKeyPress={onKeyPress}
      style={imageContainerStyle}
    >
      <UiOverlay
        trigger="hover"
        type={UiOverlayTypes.Tertiary}
        isFixedHeight
        content={{
          footer: (
            <UiRow
              gutter={[spaceXL, 0]}
              padding={spaceS}
              style={{ color: variables.themePalette.colorTextInvert }}
            >
              <UiCol>
                <UiSpace>
                  <UiIcon width={20} height={20} component={LikeSvg} />
                  {reactionsCount}
                </UiSpace>
              </UiCol>

              <UiCol>
                <UiSpace>
                  <UiIcon width={20} height={20} component={CommentSvg} />
                  {commentsCount}
                </UiSpace>
              </UiCol>
            </UiRow>
          ),
        }}
      >
        <UiImage src={src} style={defaultImageStyle} alt="Изображение из фотоальбома" />
      </UiOverlay>
    </div>
  );
};
