import { UiIcon, UiList, UiTypography, UiDrawer, UiDrawerProps, UiFlex } from '@vkph/ui';
import React, { FC } from 'react';

import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';

import { ActionsDropdownItem } from '../../../actions-dropdown';

interface Props extends UiDrawerProps {
  actions: ActionsDropdownItem[];
}

export const FileListItemActions: FC<Props> = (props) => {
  const { open, onClose, actions, title } = props;
  const { spaceS, spaceL } = useSpace();
  const drawerPaddingStyles = usePaddingStyle([spaceL, 0]);
  const actionPaddingStyles = usePaddingStyle([spaceS, spaceL]);

  return (
    <UiDrawer
      open={open}
      placement="bottom"
      onClose={onClose}
      direction="row"
      styles={{ body: drawerPaddingStyles }}
      title={title}
    >
      <UiList
        split={false}
        dataSource={actions}
        renderItem={(action) => (
          <UiList.Item
            style={actionPaddingStyles}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              action.onClick?.();
              onClose?.(e);
            }}
          >
            <UiFlex align="center" gap={spaceS}>
              <UiIcon
                color={variables.themePalette.colorIcon}
                component={action.icon}
                width={20}
                height={20}
              />
              <UiTypography.Text type="secondary" strong>
                {action.label}
              </UiTypography.Text>
            </UiFlex>
          </UiList.Item>
        )}
      />
    </UiDrawer>
  );
};
