// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9252c8e0_appearance-week{position:relative;display:flex;width:100%}._9252c8e0_appearance-week__content-columns{display:flex;width:100%}._9252c8e0_appearance-week__column{flex:1 1 218px}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/calendar-appearance/week/AppearanceWeek.scss"],"names":[],"mappings":"AACA,2BACE,iBAAA,CACA,YAAA,CACA,UAAA,CAEA,4CACE,YAAA,CACA,UAAA,CAGF,mCACE,cAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.appearance-week {\n  position: relative;\n  display: flex;\n  width: 100%;\n\n  &__content-columns {\n    display: flex;\n    width: 100%;\n  }\n\n  &__column {\n    flex: 1 1 218px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appearance-week": "_9252c8e0_appearance-week",
	"appearanceWeek": "_9252c8e0_appearance-week",
	"appearance-week__content-columns": "_9252c8e0_appearance-week__content-columns",
	"appearanceWeek__contentColumns": "_9252c8e0_appearance-week__content-columns",
	"appearance-week__column": "_9252c8e0_appearance-week__column",
	"appearanceWeek__column": "_9252c8e0_appearance-week__column"
};
export default ___CSS_LOADER_EXPORT___;
