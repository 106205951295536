import { UiCard, UiCollapse, UiDivider, UiFlex } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { ExternalLinkContact } from '@vkph/common/store/profile/api';
import { ContactLink, UserModel } from '@vkph/common/types/models';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { EmployeeContactsLinkType } from '../contact-link';
import {
  AssistantsContactsList,
  MainContactsList,
  OtherContactsList,
  ExternalLinkIcon,
  SocialLinksList,
} from '../contacts-list';

const PREVIEW_COUNT = 2;

type Props = {
  mainContacts: EmployeeContactsLinkType[];
  additionalContacts: EmployeeContactsLinkType[];
  otherContacts: ContactLink[];
  socialLinks: (ContactLink | ExternalLinkContact)[];
  messengerIcons?: Map<string, string>;
  externalLinkIcons?: Map<string, ExternalLinkIcon>;
  assistants?: UserModel[];
};

export const ContactsInfoMobile: FC<PropsWithChildren<Props>> = (props) => {
  const {
    mainContacts,
    otherContacts,
    socialLinks,
    messengerIcons,
    externalLinkIcons,
    additionalContacts,
    children,
    assistants = [],
  } = props;

  const { spaceM } = useSpace();
  const { spaceS: spaceSpx } = useSpace({ unit: 'px' });
  const cardContentPadding = usePaddingStyle([0, spaceM]);

  const hasSocialLinks = socialLinks.length > 0;

  const isCollapsable = mainContacts.length + otherContacts.length + Number(hasSocialLinks) > PREVIEW_COUNT;

  const nonCollapseMainContacts = mainContacts.slice(0, PREVIEW_COUNT);
  const nonCollapseAdditionalContacts = additionalContacts.slice(0, PREVIEW_COUNT);
  const nonCollapseOtherContacts = otherContacts.slice(0, PREVIEW_COUNT - nonCollapseMainContacts.length);
  const collapseMainContacts = mainContacts.slice(PREVIEW_COUNT);
  const collapseAdditionalContacts = additionalContacts.slice(PREVIEW_COUNT);
  const collapseOtherContacts = otherContacts.slice(nonCollapseOtherContacts.length);

  return (
    <>
      <UiCard.Content style={cardContentPadding}>
        {nonCollapseMainContacts.length > 0 && <MainContactsList contactsItems={nonCollapseMainContacts} />}

        {nonCollapseOtherContacts.length > 0 && (
          <OtherContactsList contactsItems={nonCollapseOtherContacts} messengerIcons={messengerIcons} />
        )}

        {nonCollapseAdditionalContacts.length > 0 && (
          <MainContactsList contactsItems={nonCollapseAdditionalContacts} />
        )}

        {!isCollapsable && hasSocialLinks && (
          <SocialLinksList socialLinksItems={socialLinks} externalLinkIcons={externalLinkIcons} />
        )}
      </UiCard.Content>

      {!isCollapsable && children}

      {isCollapsable && (
        <UiCollapse.Content height={0} compact>
          <UiCard.Content>
            <UiFlex vertical style={cardContentPadding}>
              {collapseMainContacts.length > 0 && <MainContactsList contactsItems={collapseMainContacts} />}
              {collapseOtherContacts.length > 0 && (
                <OtherContactsList contactsItems={collapseOtherContacts} messengerIcons={messengerIcons} />
              )}
              {collapseAdditionalContacts.length > 0 && (
                <MainContactsList contactsItems={collapseAdditionalContacts} />
              )}
              {hasSocialLinks && (
                <SocialLinksList socialLinksItems={socialLinks} externalLinkIcons={externalLinkIcons} />
              )}
            </UiFlex>

            {assistants.length > 0 && (
              <>
                <UiDivider style={{ margin: `${spaceSpx} 0` }} />
                <UiFlex vertical style={cardContentPadding}>
                  <AssistantsContactsList assistants={assistants} />
                </UiFlex>
              </>
            )}
          </UiCard.Content>
          {children}
        </UiCollapse.Content>
      )}
    </>
  );
};
