import { CellInput } from '@vkph/components';
import { UiFlex, UiForm, UiIcon, useSpace } from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import DropDownSvg from '@vkph/ui/svg/drop-down.svg';

import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<string[]>;

export const EnumCell: FC<Props> = (props) => {
  const { inputSuffix, fieldOptions, value, dataIndex, readOnly, onFinish } = props;
  const [form] = UiForm.useForm();
  const { spaceXS } = useSpace();

  const initialValues = {
    [dataIndex]: value || null,
  };

  const onSubmit = () => {
    form.submit();
  };

  const onSave = () => {
    onSubmit();
  };

  useEffect(() => {
    form.setFieldValue([dataIndex], value || null);
  }, [value]);

  return (
    <UiForm form={form} initialValues={initialValues} onFinish={onFinish}>
      <UiForm.Item style={{ marginBottom: 0 }} name={dataIndex}>
        <CellInput.Select
          onBlur={readOnly ? undefined : onSave}
          suffixIcon={inputSuffix}
          readOnly={readOnly}
          mode="tags"
          placeholder={
            <UiFlex gap={spaceXS} align="center">
              Выбрать из списка
              <UiIcon component={DropDownSvg} width={20} height={20} />
            </UiFlex>
          }
          options={fieldOptions.choices?.map((choice) => ({
            value: choice,
            label: choice,
          }))}
        />
      </UiForm.Item>
    </UiForm>
  );
};
