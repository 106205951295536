import { UiList, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { UserModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { UiUser } from '../../../user';

type Props = {
  assistants: UserModel[];
};

export const AssistantsContactsList: FC<Props> = (props) => {
  const { assistants } = props;

  const { space2XS } = useSpace();
  const listItemPadding = usePaddingStyle([space2XS, 0]);

  return (
    <>
      <UiTypography.Text type="secondary">Ассистент</UiTypography.Text>
      <UiList
        split={false}
        grid={{ column: 1 }}
        dataSource={assistants}
        renderItem={({ smallAvatar, firstName, lastName, id, isActive }) => (
          <UiList.Item style={{ margin: 0, ...listItemPadding }}>
            <UiUser.Info
              to={getRoutePath(RouteNames.Profile, { id })}
              title={getFullNameWithoutPatronymic({ firstName, lastName })}
              isActive={isActive}
              avatarProps={{
                src: smallAvatar,
                size: 'extraSmall',
              }}
            />
          </UiList.Item>
        )}
      />
    </>
  );
};
