import { UiIcon, UiImageOption, UiImageOptionProps, UiInput, UiRate } from '@vkph/ui';
import React from 'react';

import { SurveyFreeAnswerFormatType, SurveyQuestionType } from '@vkph/common/types/models';
import ArrowExpandSvg from '@vkph/ui/svg/arrow-expand.svg';
import CheckboxSvg from '@vkph/ui/svg/checkbox.svg';
import EmojiSvg from '@vkph/ui/svg/emoji.svg';
import FavoriteSvg from '@vkph/ui/svg/favorite.svg';
import ImageIconSvg from '@vkph/ui/svg/img.svg';
import RadioSvg from '@vkph/ui/svg/radio.svg';
import TextLineSvg from '@vkph/ui/svg/text-line.svg';
import TextParagraphSvg from '@vkph/ui/svg/text-paragraph.svg';

import { Poll, PollButtonTypes } from '../../poll';
import { mapQuestionTypeToMenu } from '../helpers';
import { FreeAnswer, FreeAnswerProps } from './free-answer';
import { ImageAnswer, ImageAnswerProps } from './image-answer';
import {
  SpecificAnswerTypeProps,
  SurveyFreeAnswerFormatData,
  SurveyMenuSections,
  SurveyQuestionData,
} from './types';

export const AVAILABLE_QUESTION_TYPES: Record<SurveyQuestionType, SurveyQuestionData> = {
  [SurveyQuestionType.STRING_SINGLE_CHOICE]: {
    value: SurveyQuestionType.STRING_SINGLE_CHOICE,
    label: 'Выбрать один',
    icon: RadioSvg,
    editComponent: (props) => (
      <Poll.ButtonInput buttonProps={{ disabled: true, type: PollButtonTypes.Single }} {...props} />
    ),
    responseComponent: (props) => <Poll.Button {...props} type={PollButtonTypes.Single} />,
  },
  [SurveyQuestionType.STRING_MULTIPLE_CHOICE]: {
    value: SurveyQuestionType.STRING_MULTIPLE_CHOICE,
    label: 'Выбрать несколько',
    icon: CheckboxSvg,
    editComponent: (props) => (
      <Poll.ButtonInput buttonProps={{ disabled: true, type: PollButtonTypes.Multiple }} {...props} />
    ),
    responseComponent: (props) => <Poll.Button {...props} type={PollButtonTypes.Multiple} />,
  },
  [SurveyQuestionType.IMAGE_SINGLE_CHOICE]: {
    value: SurveyQuestionType.IMAGE_SINGLE_CHOICE,
    label: 'Выбрать изображение',
    icon: ImageIconSvg,
    editComponent: (props: ImageAnswerProps) => <ImageAnswer {...props} />,
    responseComponent: (props: UiImageOptionProps) => <UiImageOption type="radio" {...props} />,
  },
  [SurveyQuestionType.RATING_STAR]: {
    value: SurveyQuestionType.RATING_STAR,
    label: 'Рейтинг звезды',
    icon: FavoriteSvg,
    editComponent: () => <UiRate.Star disabled />,
    responseComponent: (props: SpecificAnswerTypeProps) => <UiRate.Star {...props} />,
  },
  [SurveyQuestionType.RATING_SMILE]: {
    value: SurveyQuestionType.RATING_SMILE,
    label: 'Рейтинг смайлы',
    icon: EmojiSvg,
    editComponent: () => <UiRate.Emoji disabled />,
    responseComponent: (props: SpecificAnswerTypeProps) => <UiRate.Emoji {...props} />,
  },
  [SurveyQuestionType.FREE_ANSWER]: {
    value: SurveyQuestionType.FREE_ANSWER,
    label: 'Строка',
    icon: TextLineSvg,
    editComponent: (props: FreeAnswerProps) => <FreeAnswer disabled {...props} />,
    responseComponent: (props: FreeAnswerProps) => <FreeAnswer {...props} />,
  },
  [SurveyQuestionType.FREE_PARAGRAPH]: {
    value: SurveyQuestionType.FREE_PARAGRAPH,
    label: 'Абзац',
    icon: TextParagraphSvg,
    editComponent: (props) => <UiInput.TextArea disabled {...props} rows={7} />,
    responseComponent: (props) => <UiInput.TextArea {...props} rows={7} />,
  },
};

export const AVAILABLE_FREE_ANSWERS: Record<SurveyFreeAnswerFormatType, SurveyFreeAnswerFormatData> = {
  [SurveyFreeAnswerFormatType.Text]: {
    value: SurveyFreeAnswerFormatType.Text,
    label: 'Текст',
  },
  [SurveyFreeAnswerFormatType.Number]: {
    value: SurveyFreeAnswerFormatType.Number,
    label: 'Число',
  },
  [SurveyFreeAnswerFormatType.Phone]: {
    value: SurveyFreeAnswerFormatType.Phone,
    label: 'Номер телефона',
  },
  [SurveyFreeAnswerFormatType.Email]: {
    value: SurveyFreeAnswerFormatType.Email,
    label: 'Электронная почта',
  },
  [SurveyFreeAnswerFormatType.Employee]: {
    value: SurveyFreeAnswerFormatType.Employee,
    label: 'Сотрудник',
  },
};

export const SURVEY_QUESTION_MENU = [
  {
    key: SurveyMenuSections.GRAPHIC_SECTION,
    label: 'Графический рейтинг',
    icon: <UiIcon component={ArrowExpandSvg} width={20} height={20} />,
    children: [
      mapQuestionTypeToMenu(AVAILABLE_QUESTION_TYPES.RATING_SMILE),
      mapQuestionTypeToMenu(AVAILABLE_QUESTION_TYPES.RATING_STAR),
    ],
  },
  {
    key: SurveyMenuSections.MULTI_CHOICE_SECTION,
    label: 'Выбор из нескольких вариантов',
    icon: <UiIcon component={ArrowExpandSvg} width={20} height={20} />,
    children: [
      mapQuestionTypeToMenu(AVAILABLE_QUESTION_TYPES.STRING_MULTIPLE_CHOICE),
      mapQuestionTypeToMenu(AVAILABLE_QUESTION_TYPES.STRING_SINGLE_CHOICE),
      mapQuestionTypeToMenu(AVAILABLE_QUESTION_TYPES.IMAGE_SINGLE_CHOICE),
    ],
  },
  {
    key: SurveyMenuSections.INPUT_SECTION,
    label: 'Поле ввода',
    icon: <UiIcon component={ArrowExpandSvg} width={20} height={20} />,
    children: [
      mapQuestionTypeToMenu(AVAILABLE_QUESTION_TYPES.FREE_ANSWER),
      mapQuestionTypeToMenu(AVAILABLE_QUESTION_TYPES.FREE_PARAGRAPH),
    ],
  },
];
