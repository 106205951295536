import {
  UiButton,
  UiFlex,
  UiIcon,
  UiImage,
  UiSpace,
  UiTooltip,
  UiTypography,
  useTheme,
  variables,
} from '@vkph/ui';
import React, { CSSProperties, FC, useCallback } from 'react';

import { confirmEventParticipationEffect, declineEventParticipationEffect } from '@vkph/common/store/events';
import { CalendarEventModel } from '@vkph/common/types/models';
import { getFormattedTimeRange } from '@vkph/common/utils';
import CalendarAddSvg from '@vkph/ui/svg/calendar-add.svg';
import CalendarClearSvg from '@vkph/ui/svg/calendar-clear.svg';
import LocationSVG from '@vkph/ui/svg/location.svg';
import TeamOutlineSvg from '@vkph/ui/svg/team-outline.svg';
import TimeSvg from '@vkph/ui/svg/time.svg';
import VideoCameraOutlineSvg from '@vkph/ui/svg/video-camera-outline.svg';

type AppearanceTooltipProps = {
  children: React.ReactNode;
  event: CalendarEventModel;
  isLastWeekDay?: boolean;
  columnDate?: Date;
};

export const AppearanceTooltip: FC<AppearanceTooltipProps> = (props) => {
  const { event, children, isLastWeekDay } = props;
  const { id, summary, since, till, firstAttendees, image, place, format, isUserParticipate } = event;
  const { fileStorageImageUrl } = image;
  const timeRange = getFormattedTimeRange(since, till);
  const [{ variables: themeVariables }] = useTheme();
  const fontStyleBody: CSSProperties = { color: variables.themePalette.colorStrokeDark, margin: 0 };
  const buttonStyles: CSSProperties = {
    background: isUserParticipate ? undefined : themeVariables.colorBrand,
    gap: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  };

  const toggleParticipateHandler = useCallback<React.MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      isUserParticipate
        ? declineEventParticipationEffect(id)
        : confirmEventParticipationEffect({ id, body: { invitedGuestsCount: 0 } });
    },
    [isUserParticipate, id],
  );

  const tooltipPlacement = isLastWeekDay ? 'left' : undefined;

  const tooltip = (
    <>
      <UiFlex gap={16} style={{ padding: 20 }}>
        <UiFlex>
          <UiImage src={fileStorageImageUrl || undefined} width={96} height={96} />
        </UiFlex>
        <UiFlex vertical gap={12}>
          <UiTypography.Text strong>{summary}</UiTypography.Text>
          <UiFlex gap={16} align="center">
            <UiIcon.Label component={TimeSvg} height={20} width={20}>
              <UiTypography.Title style={fontStyleBody} level={5}>
                {timeRange}
              </UiTypography.Title>
            </UiIcon.Label>
            <UiIcon.Label component={TeamOutlineSvg} height={20} width={20}>
              <UiTypography.Title style={fontStyleBody} level={5}>
                {' '}
                {firstAttendees?.length}{' '}
              </UiTypography.Title>
            </UiIcon.Label>
            <UiIcon.Label component={VideoCameraOutlineSvg} height={20} width={20}>
              <UiTypography.Title style={fontStyleBody} level={5}>
                {' '}
                {format}
              </UiTypography.Title>
            </UiIcon.Label>
          </UiFlex>
          {place && (
            <UiFlex align="start">
              <UiIcon.Label component={LocationSVG} height={20} width={20} />
              <UiTypography.Text strong>{place}</UiTypography.Text>
            </UiFlex>
          )}
        </UiFlex>
      </UiFlex>
      <UiSpace.Compact block>
        <UiButton
          block
          type={isUserParticipate ? 'action-label-danger' : 'action-label'}
          onClick={toggleParticipateHandler}
          style={buttonStyles}
        >
          <UiIcon
            color={variables.themeVariables.colorTextOnBrand}
            component={isUserParticipate ? CalendarClearSvg : CalendarAddSvg}
            width={20}
            height={20}
          />
          <UiTypography.Text strong>
            {isUserParticipate ? 'Отменить участие' : 'Участвовать'}
          </UiTypography.Text>
        </UiButton>
      </UiSpace.Compact>
    </>
  );

  return (
    <UiTooltip
      title={tooltip}
      placement={tooltipPlacement}
      overlayInnerStyle={{ minWidth: 440, maxWidth: 440, borderRadius: 8, padding: 0 }}
    >
      {children}
    </UiTooltip>
  );
};
