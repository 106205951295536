import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { GetCalendarSelectedStorage } from '@vkph/common/store/calendar';
import ArrowSVG from '~calendar/components/svg/arrow.svg';

import styles from './ColumnSeparator.scss';

type Props = {
  isSidebar?: boolean;
  selectedStorage: GetCalendarSelectedStorage;
};

export const ColumnSeparator: FC<Props> = (props) => {
  const { isSidebar, selectedStorage } = props;

  if (!isSidebar) {
    return <div className={styles.columnSeparator} />;
  }

  const { isHiddenEarlyTimeSectionStore, isHiddenEarlyTimeSectionUpdated } = selectedStorage;
  const isHiddenEarlyTimeSection = useStore(isHiddenEarlyTimeSectionStore);

  return (
    <button
      className={classNames(styles.columnSeparator, {
        [styles.columnSeparator_rotateSvg]: isHiddenEarlyTimeSection,
      })}
      type="button"
      onClick={() => isHiddenEarlyTimeSectionUpdated()}
    >
      <ArrowSVG />
    </button>
  );
};
