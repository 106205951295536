import {
  UiButton,
  UiRow,
  UiIcon,
  UiSpace,
  UiTypography,
  UiUploadDraggerProps,
  UiUpload,
  useBreakpoint,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import { useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import UploadSvg from '@vkph/ui/svg/upload.svg';

import styles from './UploadDraggerArea.scss';

export interface UploadDraggerAreaProps extends Omit<UiUploadDraggerProps, 'customRequest'> {
  onUploadAttachment?: UiUploadDraggerProps['customRequest'];
  loading?: boolean;
  wrapperClassName?: string;
  description?: string;
  isHiddenUploader?: boolean;
  compact?: boolean;
  additionalInfo?: string;
  aspectRatio?: number;
}

const DEFAULT_DESCRIPTION = 'PNG или JPEG весом до 10 Мб';
const DEFAULT_COMPACT_DESCRIPTION = 'для загрузки';

export const UploadDraggerArea: FC<UploadDraggerAreaProps> = (props) => {
  const {
    onUploadAttachment,
    description,
    loading,
    disabled,
    wrapperClassName,
    showUploadList = false,
    isHiddenUploader = false,
    compact = false,
    additionalInfo,
    aspectRatio,
    ...uploadProps
  } = props;
  const isDisabled = loading || disabled;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { space2XS, spaceS, spaceL } = useSpace();

  return (
    <div
      className={classNames(styles.uploadDraggerArea, wrapperClassName, {
        [styles.uploadDraggerArea_showUploadList]: showUploadList,
        [styles.uploadDraggerArea_hideUploader]: isHiddenUploader,
      })}
      style={{ aspectRatio, maxHeight: '100%' }}
    >
      <UiUpload.Dragger {...uploadProps} disabled={isDisabled} customRequest={onUploadAttachment}>
        {compact && (
          <>
            <UiRow justify="center" padding={spaceS}>
              <UiSpace direction={isLayoutLarge ? 'horizontal' : 'vertical'} size={space2XS}>
                <UiTypography.Link strong>Нажмите</UiTypography.Link>
                <UiTypography.Text>
                  или перетащите файл {description || DEFAULT_COMPACT_DESCRIPTION}
                </UiTypography.Text>
              </UiSpace>
            </UiRow>
            {additionalInfo && (
              <UiRow justify="center" padding={[0, spaceS, spaceS]}>
                <UiTypography.Text style={{ fontSize: variables.themeSizes.heading5FontSize }}>
                  {additionalInfo}
                </UiTypography.Text>
              </UiRow>
            )}
          </>
        )}
        {!compact && (
          <UiRow padding={spaceL} align="middle" justify="center">
            <UiSpace direction="vertical" align="center">
              <UiButton
                round
                size="large"
                type="primary"
                disabled={isDisabled}
                icon={<UiIcon component={UploadSvg} width={20} height={20} />}
              />
              <UiTypography.Title level={4}>Кликните или перетащите файл сюда</UiTypography.Title>
              <UiTypography.Text>{description || DEFAULT_DESCRIPTION}</UiTypography.Text>
            </UiSpace>
          </UiRow>
        )}
      </UiUpload.Dragger>
    </div>
  );
};
