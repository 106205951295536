import { ListColumnFieldType } from '@vkph/common/types/models';
import AttachSvg from '@vkph/ui/svg/attach.svg';
import BlogSvg from '@vkph/ui/svg/blog.svg';
import BookSvg from '@vkph/ui/svg/book.svg';
import CalendarSvg from '@vkph/ui/svg/calendar.svg';
import CheckboxSvg from '@vkph/ui/svg/checkbox.svg';
import HashtagSvg from '@vkph/ui/svg/hashtag_outline_28.svg';
import LinkSvg from '@vkph/ui/svg/link.svg';
import TextSvg from '@vkph/ui/svg/text.svg';
import UserSvg from '@vkph/ui/svg/user.svg';

export type ListColumnTypeParams = {
  icon: SvgrComponent;
  label: string;
  description: string;
};

export const listColumnTypeFields: Record<ListColumnFieldType, ListColumnTypeParams> = {
  [ListColumnFieldType.Text]: {
    icon: TextSvg,
    label: 'Текстовый',
    description:
      'Можно указать текстовые значения или ввести текст из нескольких предложений. Полезно для описаний',
  },
  [ListColumnFieldType.Numeric]: {
    icon: HashtagSvg,
    label: 'Числовой',
    description: 'Указывайте числовые значения. Полезно для отслеживания количества и цен',
  },
  [ListColumnFieldType.Boolean]: {
    icon: CheckboxSvg,
    label: 'Логический',
    description:
      'Используйте этот тип столбца, чтобы иметь возможность проставить для каждой ячейки столбца логику: да или нет, включить или выключить, истина или ложь',
  },
  [ListColumnFieldType.User]: {
    icon: UserSvg,
    label: 'Пользователи',
    description: 'Вы можете указать конкретных сотрудников или группу. Полезно для назначения ответственных',
  },
  [ListColumnFieldType.Datetime]: {
    icon: CalendarSvg,
    label: 'Дата и время',
    description:
      'Вы можете указать дату или диапазон дат (время необязательно). Полезно для определения сроков',
  },
  [ListColumnFieldType.Enum]: {
    icon: BlogSvg,
    label: 'Выбор из списка',
    description: 'Выберите из списка значений нужные. Полезно для категоризации',
  },
  [ListColumnFieldType.Dictionary]: {
    icon: BookSvg,
    label: 'Справочник',
    description:
      'Подключите справочник и укажите группу значений. Полезно для подключения элементов в вашем рабочем пространстве',
  },
  [ListColumnFieldType.Hyperlink]: {
    icon: LinkSvg,
    label: 'Гиперссылка',
    description: 'Добавьте ссылку на веб-сайт. Сайт открывается в новой вкладке',
  },
  [ListColumnFieldType.File]: {
    icon: AttachSvg,
    label: 'Файловый',
    description: 'Возможность выбора файла из файлового хранилища сайта',
  },
};

export const CREATE_COLUMNS_ID = 'CREATE_COLUMN';
export const MAX_COLUMNS_COUNT = 300;

type sortableOptions = {
  sortable: string;
  reverseSortable: string;
};

export const tooltipTextByListColumnType: Record<ListColumnFieldType, sortableOptions> = {
  [ListColumnFieldType.Text]: {
    sortable: 'Нажмите для сортировки по алфавиту',
    reverseSortable: 'Нажмите для сортировки по алфавиту в обратном порядке',
  },
  [ListColumnFieldType.Datetime]: {
    sortable: 'Нажмите для сортировки по дате',
    reverseSortable: 'Нажмите для сортировки по дате в обратном порядке',
  },
  [ListColumnFieldType.Numeric]: {
    sortable: 'Нажмите для сортировки по возрастанию',
    reverseSortable: 'Нажмите для сортировки по убыванию',
  },
  [ListColumnFieldType.Dictionary]: {
    sortable: '',
    reverseSortable: '',
  },
  [ListColumnFieldType.Boolean]: {
    sortable: '',
    reverseSortable: '',
  },
  [ListColumnFieldType.Enum]: {
    sortable: '',
    reverseSortable: '',
  },
  [ListColumnFieldType.Hyperlink]: {
    sortable: '',
    reverseSortable: '',
  },
  [ListColumnFieldType.User]: {
    sortable: '',
    reverseSortable: '',
  },
  [ListColumnFieldType.File]: {
    sortable: '',
    reverseSortable: '',
  },
};

export enum DynamicTablePermission {
  EditCells = 'EditCells',
  EditRows = 'EditRows',
  DeleteRows = 'DeleteRows',
  EditColumns = 'EditColumns',
  DeleteColumns = 'DeleteColumns',
}
