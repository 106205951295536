import { Plugin } from '@ckeditor/ckeditor5-core';
import { Notification } from '@ckeditor/ckeditor5-ui';
import { FileRepository } from '@ckeditor/ckeditor5-upload';

import { SimpleFileUploadCommand } from './SimpleFileUploadCommand';

export class SimpleFileEditingPlugin extends Plugin {
  static get requires() {
    return [FileRepository, Notification];
  }

  static get pluginName() {
    return 'SimpleFileEditing';
  }

  init() {
    const { editor } = this;

    // Register simpleFileUpload command.
    editor.commands.add('simpleFileUpload', new SimpleFileUploadCommand(editor));
  }

  destroy() {
    return super.destroy();
  }
}
