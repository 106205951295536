import { UiFlex, UiForm, UiSelect } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, useMemo, useRef } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getFileStorageListStorage, getSiteStorage } from '@vkph/common/store';
import { SiteId } from '@vkph/common/types/models';
import { requiredRule } from '@vkph/common/utils';

interface Props {
  id: SiteId;
}

export const FileFormFields: FC<Props> = (props) => {
  const { id } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const siteStorage = useMemo(getSiteStorage, []);

  const { data: siteData } = useAbstractStorage(siteStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { id },
  });

  const rootFolderId = siteData?.filestorageRootFolderId ?? '';

  const fileStorageListStorage = useMemo(() => getFileStorageListStorage(rootFolderId), [rootFolderId]);

  const { loading: isFileStorageListLoading } = useAbstractStorage(fileStorageListStorage.storage, {
    autoFetchAndRefetch: Boolean(rootFolderId),
    autoFetchParams: {
      pageNumber: 1,
      pageSize: 1000,
      entryId: rootFolderId,
    },
  });

  const options = useStoreMap(fileStorageListStorage.storage.store, ({ data }) => {
    return data.map((row) => ({ label: row.name, value: row.id })) || [];
  });

  return (
    <UiFlex vertical ref={ref}>
      <UiForm.Item name="folderId" label="Файловые хранилища сайта" rules={[requiredRule]} required>
        <UiSelect
          disabled={isFileStorageListLoading}
          placeholder="Выбрать"
          options={options}
          getPopupContainer={() => ref.current || document.body}
        />
      </UiForm.Item>
    </UiFlex>
  );
};
