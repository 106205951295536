import { UiInput, UiInputRef } from '@vkph/ui';
import classNames from 'classnames';
import React, { ForwardRefExoticComponent, RefAttributes, forwardRef } from 'react';

import styles from './CellInput.scss';
import { CellInputButton } from './button/CellInputButton';
import { CellInputLink } from './link/CellInputLink';
import { CellInputSelect } from './select/CellInputSelect';
import { CellInputProps } from './types';

type CellInputCompositionProps = {
  Link: typeof CellInputLink;
  Select: typeof CellInputSelect;
  Button: typeof CellInputButton;
};

type CellInputPropsWithRef = ForwardRefExoticComponent<CellInputProps & RefAttributes<UiInputRef>>;

const CellInputBase: CellInputPropsWithRef = forwardRef((props, ref) => {
  const { items, readOnly, ...restProps } = props;

  return (
    <UiInput
      {...restProps}
      readOnly={readOnly}
      ref={ref}
      className={classNames(styles.uiCellInput, { [styles.uiCellInput_readOnly]: readOnly })}
    />
  );
});

export const CellInput = CellInputBase as CellInputPropsWithRef & CellInputCompositionProps;

CellInput.Link = CellInputLink;
CellInput.Select = CellInputSelect;
CellInput.Button = CellInputButton;
