import {
  UiCol,
  UiRow,
  UiInput,
  UiSegmented,
  UiSegmentedOption,
  UiOptionItem,
  UiSelect,
  UiSpinner,
  UiTag,
  UiTagTypes,
  UiForm,
} from '@vkph/ui';
import { useStore, useStoreMap } from 'effector-react';
import React, { FC, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { getReferencesPrioritiesStorage, getReferencesStatusesStorage } from '@vkph/common/store/tasks';
import { TasksGanttFilterForm } from '@vkph/common/types/tasks';

import { ganttFilterStore, GanttViewType } from '../store';
import { TaskPriority } from '../task-priority/TaskPriority';
import styles from './GanttFilter.scss';

const DEFAULT_STORAGE_PARAMS = {
  autoFetchAndRefetch: true,
  cancelPendingRequestOnUnmount: true,
};

const intervalViewType: UiSegmentedOption<GanttViewType>[] = [
  {
    label: 'Мес',
    value: GanttViewType.Month,
  },
  {
    label: 'Год',
    value: GanttViewType.Year,
  },
];

const initialValues: TasksGanttFilterForm = {
  search: '',
};

export const GanttFilter: FC = () => {
  const currentUserProfile = useCurrentProfile();
  const [form] = UiForm.useForm<TasksGanttFilterForm>();

  const { store: filterStore, updateStoreEvent: updateFilterParamsEvent } = ganttFilterStore;
  const { viewType: ganttViewType } = useStore(filterStore);
  const { storage: prioritiesStorage } = useMemo(getReferencesPrioritiesStorage, []);
  const { storage: referencesStatusesStorage } = useMemo(getReferencesStatusesStorage, []);

  const { loading: isReferencesStatusesLoading } = useAbstractStorage(
    referencesStatusesStorage,
    DEFAULT_STORAGE_PARAMS,
  );
  const { loading: isPrioritiesLoading } = useAbstractStorage(prioritiesStorage, DEFAULT_STORAGE_PARAMS);

  const prioritiesOptions = useStoreMap(prioritiesStorage.store, ({ data }) =>
    data.map(({ id }) => ({
      value: id,
      label: <TaskPriority key={id} level={id} />,
    })),
  );

  const referencesStatusesOptions = useStoreMap(referencesStatusesStorage.store, ({ data }) =>
    data.array.map(({ id, name, color }) => ({
      value: id,
      label: <UiTag title={name} color={color} type={UiTagTypes.Dot} rhomb style={{ paddingLeft: 4 }} />,
    })),
  );

  const currentUserOptions: UiOptionItem[] = useMemo(
    () => [
      {
        label: 'Только Я',
        value: currentUserProfile?.id || '',
      },
    ],
    [currentUserProfile?.id],
  );

  const onChangeViewType = (value: GanttViewType) => {
    updateFilterParamsEvent({ viewType: value });
  };

  const updateTasksFilterDebounced = useDebouncedCallback((value: TasksGanttFilterForm) => {
    updateFilterParamsEvent(value);
  }, 400);

  const onValuesChange = (value: TasksGanttFilterForm) => {
    const { search, ...rest } = value;

    search !== undefined ? updateTasksFilterDebounced({ search }) : updateFilterParamsEvent(rest);
  };

  const isLoading = isReferencesStatusesLoading || isPrioritiesLoading;

  return (
    <div className={styles.ganttFilter}>
      <UiSpinner spinning={isLoading}>
        <UiForm
          form={form}
          initialValues={initialValues}
          className={styles.ganttFilter__form}
          onValuesChange={onValuesChange}
        >
          <UiRow gutter={[20, 0]} justify="space-between" wrap={false}>
            <UiCol>
              <UiRow gutter={[20, 0]}>
                <UiCol>
                  <UiSegmented
                    size="large"
                    options={intervalViewType}
                    value={ganttViewType}
                    onChange={onChangeViewType}
                    defaultValue={GanttViewType.Year}
                  />
                </UiCol>
              </UiRow>
            </UiCol>
            <UiCol flex="auto">
              <UiRow gutter={[20, 0]}>
                <UiCol span={9}>
                  <UiForm.Item name="query">
                    <UiInput.Search size="large" placeholder="Поиск по задачам" allowClear />
                  </UiForm.Item>
                </UiCol>
                <UiCol span={5}>
                  <UiForm.Item name="assignee">
                    <UiSelect
                      size="large"
                      placeholder="Исполнитель: Все"
                      options={currentUserOptions}
                      allowClear
                    />
                  </UiForm.Item>
                </UiCol>
                <UiCol span={5}>
                  <UiForm.Item name="priorityId">
                    <UiSelect
                      placeholder="Приоритет: Все"
                      size="large"
                      options={prioritiesOptions}
                      allowClear
                    />
                  </UiForm.Item>
                </UiCol>
                <UiCol span={5}>
                  <UiForm.Item name="statusId">
                    <UiSelect
                      placeholder="Статус: Все"
                      size="large"
                      options={referencesStatusesOptions}
                      allowClear
                    />
                  </UiForm.Item>
                </UiCol>
              </UiRow>
            </UiCol>
          </UiRow>
        </UiForm>
      </UiSpinner>
    </div>
  );
};
