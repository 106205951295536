import { Calendar } from '@vkph/components';
import classNames from 'classnames';
import { areIntervalsOverlapping, format, getISOWeek, set as setDate } from 'date-fns';
import { useStore } from 'effector-react';
import React, { useMemo, useRef, useState, useEffect, FC } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { appearanceDatesStorage, GetCalendarEventsStorage } from '@vkph/common/store/calendar';
import { GetCalendarSelectedStorage } from '@vkph/common/store/calendar/selected-storage';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { LAYOUT_HOUR_IN_PX, getDateRangeByInterval } from '@vkph/common/utils';

import { HIDDEN_HOUR } from '../constants';
import { CalendarEventPosition } from '../types';
import styles from './AppearanceColumn.scss';
import { ColumnEvent } from './event/ColumnEvent';
import { ColumnFill } from './fill/ColumnFill';
import { ColumnSeparator } from './separator/ColumnSeparator';

const hoursInDayArray = new Array<null>(24).fill(null);

export interface Props {
  className?: string;
  isDayView?: boolean;
  isSidebar?: boolean;
  columnDate?: Date;
  isLastWeekDay?: boolean;
  eventsStorage: GetCalendarEventsStorage;
  selectedStorage: GetCalendarSelectedStorage;
}

export const AppearanceColumn: FC<Props> = (props) => {
  const { className, isSidebar, columnDate, isLastWeekDay, isDayView, eventsStorage, selectedStorage } =
    props;
  const { isHiddenEarlyTimeSectionStore, eventTypesFilterStore } = selectedStorage;
  const isHiddenEarlyTimeSection = useStore(isHiddenEarlyTimeSectionStore);
  const eventTypesFilter = useStore(eventTypesFilterStore);
  const appearanceDatesStore = useStore(appearanceDatesStorage.store);

  const { data: reducedEvents, refetchWithLastParams } = useAbstractStorage(eventsStorage.storage);
  const { siteId } = eventsStorage.storage.getLastRequestParams() || {};

  const displayWeekNumber = getISOWeek(appearanceDatesStore.sinceDate);
  const sidebarTitle = `${displayWeekNumber || 0}\nНЕД`;

  const hiddenWrapperEl = useRef<HTMLDivElement | null>(null);

  const calcHeightWrapper = (): string => {
    const { current: currentEl } = hiddenWrapperEl;

    if (!isHiddenEarlyTimeSection && currentEl) {
      return `${currentEl.scrollHeight}px`;
    }

    return '0';
  };

  const [heightWrapper, setHeightWrapper] = useState(calcHeightWrapper());

  useEffect(() => {
    setHeightWrapper(calcHeightWrapper());
  }, [hiddenWrapperEl, isHiddenEarlyTimeSection]);

  const [hiddenFillsEls, visibleFillsEls] = hoursInDayArray.reduce<JSX.Element[][]>(
    (acc, _, hour) => {
      const fillKey = format(columnDate || Date.now(), `yyyy.MM.dd'T${hour}:00'`);

      const accIndex = hour < HIDDEN_HOUR ? 0 : 1;

      acc[accIndex].push(
        <ColumnFill
          eventsStorage={eventsStorage}
          key={fillKey}
          hour={hour}
          isSidebar={isSidebar}
          columnDate={columnDate}
        />,
      );

      return acc;
    },
    [[], []],
  );

  const hiddenHourStartDate = useMemo(() => setDate(columnDate || new Date(), { hours: 0, minutes: 0 }), []);
  const hiddenHourEndDate = useMemo(
    () => setDate(hiddenHourStartDate, { hours: HIDDEN_HOUR, minutes: 0 }),
    [],
  );
  const snapWidth = LAYOUT_HOUR_IN_PX / 4;

  const getOnDragEventCreateHandler = (sinceDate: Date) => (x: number, width: number) => {
    const { since, till } = getDateRangeByInterval({
      sinceDate,
      start: x,
      duration: width,
      pxInHour: LAYOUT_HOUR_IN_PX + 1,
    });

    openGlobalModal(GlobalModalNames.CalendarCreateUpdateMeeting, {
      initialEventFormValues: {
        sinceDate: since,
        tillDate: till,
      },
      onSuccess: refetchWithLastParams,
      siteId,
    });
  };

  const keyOfReducedEvents = columnDate ? format(columnDate, 'yyyy.MM.dd') : undefined;
  const eventsOnDay = reducedEvents && keyOfReducedEvents ? reducedEvents[keyOfReducedEvents] : undefined;

  type EventColumn = { id: string; start: Date; end: Date }[];
  type EventGroup = EventColumn[];

  // Вычисляем позиционирование элементов
  // 3 этапа: сортируем -> создаем вспомогательный объект -> вычисляем позиции
  const eventPositions = useMemo(() => {
    const sortedEventsOnDay = eventsOnDay?.slice();

    const eventGroups: EventGroup[] = [[]];

    // Создаем вспомогательный объект - Распределяем эвенты по группам с колонками
    sortedEventsOnDay?.forEach(({ id, since, till }) => {
      const sinceDate = new Date(since);
      const tillDate = new Date(till);

      // Кейс для первого элемента
      if (eventGroups[0].length === 0) {
        eventGroups[0].push([{ id, start: new Date(sinceDate), end: new Date(tillDate) }]);
        return;
      }

      // Если данный эвент не пересекает не один из эвентов в данной группе, то создается новая группа
      let isOverlappingCurrentGroup = false;
      // Далее в блоке - рассматриваем все относительно текущей группы
      const currentGroup = eventGroups[eventGroups.length - 1];

      // Вычисляем колонки, которые не пересекают текущий эвент
      const noOverlappingColumns: number[] = [];

      currentGroup.forEach((column, columnIndex) => {
        // Для каждого эвента уже добавленного в группу, определям какие колонки данной группы он пересекает
        const isOverlappingSomeColumnEvent = column.some(({ start, end }) => {
          const isOverlapping = areIntervalsOverlapping({ start: sinceDate, end: tillDate }, { start, end });

          if (isOverlapping) {
            isOverlappingCurrentGroup = true;
          }

          return isOverlapping;
        });

        if (!isOverlappingSomeColumnEvent) {
          noOverlappingColumns.push(columnIndex);
        }
      });

      const firstNoOverlappingColumn = noOverlappingColumns[0];
      const isOverlapAllColumns = noOverlappingColumns.length === 0;

      if (isOverlappingCurrentGroup) {
        if (isOverlapAllColumns) {
          // Добовляем новую колонку с текущим эвентом
          currentGroup.push([{ id, start: sinceDate, end: tillDate }]);
        } else {
          // Пушим эвент в первую не пересекаемую им колонку
          currentGroup[firstNoOverlappingColumn].push({ id, start: sinceDate, end: tillDate });
        }
      } else {
        // Добовляем новую группу с одной колонкой и текущим эвентом в ней
        eventGroups.push([[{ id, start: sinceDate, end: tillDate }]]);
      }
    });

    // Получаем позиционарование элементов из вспомогательного объекта
    const positions: Record<string, CalendarEventPosition> = {};

    eventGroups.forEach((group) => {
      group.forEach((column, columnIndex) =>
        column.forEach(({ id, start, end }) => {
          // Рекурсивно вычисляем нужно ли расширить эвент на несколько колонок
          let colspan = 1;
          const checkNextColumn = (currentColumnIndex: number) => {
            const lastColumnIndex = group.length - 1;
            const nextColumnIndex = currentColumnIndex + 1;
            const isAddColspan =
              currentColumnIndex === lastColumnIndex
                ? false
                : !group[nextColumnIndex].some((nextColumnEvent) =>
                    areIntervalsOverlapping(
                      { start: nextColumnEvent.start, end: nextColumnEvent.end },
                      { start, end },
                    ),
                  );

            if (isAddColspan) {
              colspan++;
              checkNextColumn(nextColumnIndex);
            }
          };

          checkNextColumn(columnIndex);

          positions[id] = {
            colspan,
            column: columnIndex,
            totalColumns: group.length,
          };
        }),
      );
    });

    return positions;
  }, [eventsOnDay]);

  const eventsEls = eventsOnDay?.map((event) => {
    if (eventTypesFilter.indexOf(event.eventType) > -1) {
      return (
        <ColumnEvent
          key={event.id}
          event={event}
          eventsStorage={eventsStorage}
          selectedStorage={selectedStorage}
          position={eventPositions[event.id]}
          isLastWeekDay={isLastWeekDay}
          columnDate={columnDate as Date}
          isDayView={isDayView}
        />
      );
    }

    return null;
  });

  return (
    <div
      className={classNames(
        styles.appearanceColumn,
        { [styles.appearanceColumn_isSidebar]: isSidebar },
        className,
      )}
    >
      <Calendar.Heading
        isSidebar={isSidebar}
        columnDate={columnDate}
        isDayView={isDayView}
        sidebarTitle={sidebarTitle}
      />
      <div className={styles.appearanceColumn__workplaceContainer}>
        <div className={styles.appearanceColumn__additionalSection}>
          <div
            ref={hiddenWrapperEl}
            style={{ height: heightWrapper }}
            className={styles.appearanceColumn__hiddenWrapper}
          >
            <Calendar.CreatingSection
              snapWidth={snapWidth}
              sinceDate={hiddenHourStartDate}
              onDragEnd={getOnDragEventCreateHandler(hiddenHourStartDate)}
            >
              {hiddenFillsEls}
            </Calendar.CreatingSection>
          </div>
          <ColumnSeparator selectedStorage={selectedStorage} isSidebar={isSidebar} />
        </div>
        <Calendar.CreatingSection
          snapWidth={snapWidth}
          sinceDate={hiddenHourEndDate}
          onDragEnd={getOnDragEventCreateHandler(hiddenHourEndDate)}
        >
          <div
            className={classNames(styles.appearanceColumn__eventsSection, {
              [styles.appearanceColumn__eventsSection_largeMargins]: isDayView,
            })}
          >
            {eventsEls}
          </div>
          {visibleFillsEls}
        </Calendar.CreatingSection>
      </div>
    </div>
  );
};
