import { isToday } from 'date-fns/esm';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import {
  appearanceDatesStorage,
  GetCalendarEventsStorage,
  GetCalendarSelectedStorage,
} from '@vkph/common/store/calendar';
import { NotificationPayloads, NotificationTypes, useNotificationEvent } from '@vkph/common/utils';

import { AppearanceColumn } from '../column/AppearanceColumn';
import { AppearanceTimeline } from '../timeline/AppearanceTimeline';
import styles from './AppearanceDay.scss';

type Props = {
  eventsStorage: GetCalendarEventsStorage;
  selectedStorage: GetCalendarSelectedStorage;
};

export const AppearanceDay: FC<Props> = (props) => {
  const { eventsStorage, selectedStorage } = props;
  const { sinceDate } = useStore(appearanceDatesStorage.store);
  const { addNotificationEvent } = eventsStorage;
  const isShowTimeline = isToday(sinceDate);

  const notificationListener = (newEvent: NotificationPayloads[NotificationTypes.CalendarEventCreated]) => {
    addNotificationEvent(newEvent.event);
  };

  useNotificationEvent(NotificationTypes.CalendarEventCreated, notificationListener);

  return (
    <div className={styles.appearanceDay}>
      <AppearanceColumn eventsStorage={eventsStorage} selectedStorage={selectedStorage} isSidebar />
      <div className={styles.appearanceDay__contentColumns}>
        <AppearanceColumn
          eventsStorage={eventsStorage}
          selectedStorage={selectedStorage}
          isDayView
          className={styles.appearanceDay__column}
          columnDate={sinceDate}
        />
      </div>
      {isShowTimeline && <AppearanceTimeline selectedStorage={selectedStorage} />}
    </div>
  );
};
