import { UiButton, UiButtonDecoratorProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

interface Props extends Omit<UiButtonDecoratorProps, 'onClick'> {
  onClick: () => void;
  readOnly?: boolean;
}

export const MonthFillWrapper: FC<PropsWithChildren<Props>> = (props) => {
  const { children, onClick, readOnly, ...rest } = props;

  const onKeyDown = ({ key }: React.KeyboardEvent) => {
    if (key === 'Enter') {
      onClick();
    }
  };

  if (readOnly) {
    return <div {...rest}>{children}</div>;
  }

  return (
    <UiButton.Decorator onClick={onClick} onKeyDown={onKeyDown} {...rest}>
      {children}
    </UiButton.Decorator>
  );
};
