import { UiIcon, UiInputProps } from '@vkph/ui';
import React, { ForwardRefRenderFunction, PropsWithChildren } from 'react';
import { animated, SpringConfig, useSpring } from 'react-spring';

import { useTheme } from '@vkph/ui/providers/theme';
import ClearSVG from '@vkph/ui/svg/clear.svg';
import SearchSVG from '@vkph/ui/svg/search.svg';

import styles from './AnimatedSearch.scss';

const AnimatedSearchInputBase = animated.input;
const headerSearchAnimationConfig: SpringConfig = { duration: 300 };

interface Props extends Omit<UiInputProps, 'value' | 'size' | 'prefix'> {
  isFocused?: boolean;
  value?: string;
}

const AnimatedSearchInput: ForwardRefRenderFunction<HTMLInputElement, PropsWithChildren<Props>> = (
  props,
  ref,
) => {
  const { value, isFocused, children, onClear, style: outerStyles, ...inputProps } = props;
  const [{ variables: themeVariables }] = useTheme();

  const inputStyles = useSpring({
    from: {
      background: themeVariables.colorInputOnBrand,
    },
    to: {
      background: isFocused ? themeVariables.colorHoverOnBrand : themeVariables.colorInputOnBrand,
    },
    config: headerSearchAnimationConfig,
  });

  const searchStyles = useSpring({
    from: {
      background: isFocused ? themeVariables.colorBrand : 'unset',
    },
    to: {
      background: isFocused ? themeVariables.colorHoverOnBrand : 'unset',
    },
    config: headerSearchAnimationConfig,
  });

  return (
    <animated.div className={styles.animatedSearch} style={{ ...searchStyles, ...outerStyles }}>
      <div className={styles.animatedSearch__inputWrapper}>
        <AnimatedSearchInputBase
          ref={ref}
          type="text"
          placeholder={isFocused ? '' : 'Поиск'}
          value={value}
          {...inputProps}
          className={styles.animatedSearch__input}
          style={inputStyles}
        />
        {isFocused && value && value.length > 0 && onClear && (
          <UiIcon
            className={styles.animatedSearch__cross}
            width={20}
            height={20}
            component={ClearSVG}
            onClick={onClear}
          />
        )}
      </div>

      {isFocused && children}

      <SearchSVG className={styles.animatedSearch__icon} style={{ color: themeVariables.colorBgPrimary }} />
    </animated.div>
  );
};

export const AnimatedSearchInputWidget = React.forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  AnimatedSearchInput,
);
