// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4097dd61_appearance-day{position:relative;display:flex;width:100%}._4097dd61_appearance-day__content-columns{display:flex;width:100%}._4097dd61_appearance-day__column{flex-grow:1}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/calendar-appearance/day/AppearanceDay.scss"],"names":[],"mappings":"AACA,0BACE,iBAAA,CACA,YAAA,CACA,UAAA,CAEA,2CACE,YAAA,CACA,UAAA,CAGF,kCACE,WAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.appearance-day {\n  position: relative;\n  display: flex;\n  width: 100%;\n\n  &__content-columns {\n    display: flex;\n    width: 100%;\n  }\n\n  &__column {\n    flex-grow: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appearance-day": "_4097dd61_appearance-day",
	"appearanceDay": "_4097dd61_appearance-day",
	"appearance-day__content-columns": "_4097dd61_appearance-day__content-columns",
	"appearanceDay__contentColumns": "_4097dd61_appearance-day__content-columns",
	"appearance-day__column": "_4097dd61_appearance-day__column",
	"appearanceDay__column": "_4097dd61_appearance-day__column"
};
export default ___CSS_LOADER_EXPORT___;
