import { UiDatePicker, UiFlex, UiForm, UiSwitch, UiTimePicker, useSpace } from '@vkph/ui';
import React, { FC, useRef } from 'react';

export const DatetimeFormFields: FC = () => {
  const { spaceXL, spaceXS } = useSpace();
  const ref = useRef<HTMLDivElement>(null);
  const dateErrorMessage = 'Пожалуйста, введите правильный формат даты ДД.ММ.ГГГГ ЧЧ:ММ';

  return (
    <UiFlex gap={spaceXL} vertical ref={ref}>
      <UiForm.Item noStyle name="onlyDate" valuePropName="checked" initialValue={false}>
        <UiSwitch label="Отображать только дату в столбце (без времени)" />
      </UiForm.Item>

      <UiForm.Item label="Данные ячейки для вставки во все ячейки столбца">
        <UiFlex gap={spaceXS}>
          <UiForm.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const isDefaultDate = Boolean(getFieldValue('defaultDate'));
              const isDefaultTime = Boolean(getFieldValue('defaultTime'));
              const onlyDate = Boolean(getFieldValue('onlyDate'));

              return (
                <>
                  <UiForm.Item
                    noStyle
                    name="defaultDate"
                    rules={[
                      {
                        required: onlyDate ? false : isDefaultTime,
                        message: dateErrorMessage,
                      },
                    ]}
                  >
                    <UiDatePicker
                      size="large"
                      style={{ width: '100%' }}
                      getPopupContainer={() => ref.current || document.body}
                    />
                  </UiForm.Item>
                  <UiForm.Item
                    noStyle
                    name="defaultTime"
                    hidden={onlyDate}
                    rules={[
                      {
                        required: !onlyDate && isDefaultDate,
                        message: dateErrorMessage,
                      },
                    ]}
                  >
                    <UiTimePicker size="large" getPopupContainer={() => ref.current || document.body} />
                  </UiForm.Item>
                </>
              );
            }}
          </UiForm.Item>
        </UiFlex>
      </UiForm.Item>
    </UiFlex>
  );
};
