import { UiFlex, UiIcon, UiIconProps, UiTooltip } from '@vkph/ui';
import React, { FC } from 'react';

import { variables } from '@vkph/ui/providers/theme';

import { EmployeeContactsLinkType } from '../EmployeeContactsLink';

type EmployeeContactsLinkIconProps = Pick<UiIconProps, 'component' | 'height'> &
  Pick<EmployeeContactsLinkType, 'tooltipTitle' | 'showTooltip'>;

export const EmployeeContactsLinkIcon: FC<EmployeeContactsLinkIconProps> = (props) => {
  const { component, height = 20, tooltipTitle, showTooltip } = props;

  return (
    <UiTooltip title={showTooltip && tooltipTitle}>
      <UiFlex>
        <UiIcon component={component} color={variables.themePalette.colorIcon} width={20} height={height} />
      </UiFlex>
    </UiTooltip>
  );
};
