import { MentionFeed, MentionFeedObjectItem } from '@ckeditor/ckeditor5-mention/src/mentionconfig';

import AvatarUserSvg from '../svg/avatar-user.svg';
import { EditorMentionType, GetEditorMentionFeedParams } from '../types';

const userMentionRenderer = (item: MentionFeedObjectItem) => {
  const itemElement = document.createElement('span');
  const usernameElement = document.createElement('span');
  const avatarElement = document.createElement('img');

  itemElement.classList.add('mention-user-item');
  // @ts-expect-error: TODO: исправить ошибки типизаци
  itemElement.id = `mention-list-item-id-${item.userId}`;

  avatarElement.classList.add('mention-user-avatar');
  // @ts-expect-error: TODO: исправить ошибки типизаци
  avatarElement.src = item.avatar || AvatarUserSvg;

  // @ts-expect-error: TODO: исправить ошибки типизаци
  if (!item.avatar) {
    avatarElement.classList.add('mention-default-avatar');
  }

  usernameElement.classList.add('mention-username');
  // @ts-expect-error: TODO: исправить ошибки типизаци
  usernameElement.textContent = item.name;

  itemElement.prepend(avatarElement);
  itemElement.appendChild(usernameElement);

  return itemElement;
};

export const editorMentionFeedConfig = {
  [EditorMentionType.User]: {
    marker: '@',
    itemRenderer: userMentionRenderer,
    minimumCharacters: 0,
    dropdownLimit: 100,
  },
} satisfies Record<string, Partial<MentionFeed>>;

export const getEditorMentionFeed = <T extends MentionFeedObjectItem>(
  params: GetEditorMentionFeedParams<T>,
) => {
  const { type, onSearchMentions } = params;

  const getMentionFeed = (query: string): Promise<T[]> => {
    return new Promise((resolve) => {
      onSearchMentions(query)
        .then((data) => resolve(data))
        .catch(() => resolve([]));
    });
  };

  return {
    ...editorMentionFeedConfig[type],
    feed: getMentionFeed,
  };
};
