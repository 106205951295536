import { Plugin } from '@ckeditor/ckeditor5-core';
import downloadIcon from '@ckeditor/ckeditor5-core/theme/icons/align-bottom.svg';
import { FileDialogButtonView } from '@ckeditor/ckeditor5-upload';

export class SimpleFileUiPlugin extends Plugin {
  init() {
    const { editor } = this;

    editor.ui.componentFactory.add('simpleFileUpload', (locale) => {
      const view = new FileDialogButtonView(locale);
      const { commands, config } = editor;
      const command = commands.get('simpleFileUpload');
      const { allowedTypes } = config.get('simpleFile') || {};

      view.set({
        acceptedType: allowedTypes?.join(','),
        allowMultipleFiles: false,
      });

      view.buttonView.set({
        label: 'Прикрепить файл',
        tooltip: true,
        icon: downloadIcon,
      });

      if (command) {
        view.buttonView.bind('isEnabled').to(command);
      }

      view.on('done', (_, file) => {
        const fileToUpload = file;

        if (fileToUpload.length) {
          editor.execute('simpleFileUpload', { file: fileToUpload });
        }
      });

      return view;
    });
  }
}
