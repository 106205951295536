import { UiForm, UiTreeSelect, UiTreeSelectProps } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, Key, useCallback, useEffect, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getOrganizationsRecordsStorageOld, getStructureStorageOld } from '@vkph/common/store';
import { OrganizationModel } from '@vkph/common/types/models';

type Props = {
  dataIndex: string;
};

const getUpdatedNode = (child: OrganizationModel, newNode?: OrganizationModel) => {
  const isChild = !child.hasChildren && !child.children;

  return {
    id: child.id,
    pId: newNode ? newNode.id : '',
    key: newNode ? `${child.id}-${newNode.id}` : child.id,
    value: newNode ? `${child.id}-${newNode.id}` : child.id,
    title: child.name,
    isLeaf: isChild,
    checkable: isChild,
  };
};

const getUpdatedTreeData = (
  treeData: UiTreeSelectProps['treeData'],
  newNode: OrganizationModel,
  key?: Key,
) => {
  return treeData?.map((node) => {
    if (node.key === key && newNode.children) {
      return {
        ...node,
        children: newNode.children.map((child) => {
          return getUpdatedNode(child, newNode);
        }),
      };
    }

    return node;
  });
};

export const DictionaryOrganizations: FC<Props> = (props) => {
  const { dataIndex } = props;

  const organizationsRecordsStorage = useMemo(getOrganizationsRecordsStorageOld, []);
  const structureStorage = useMemo(getStructureStorageOld, []);

  const organizationsRecordsOptions = useStoreMap(organizationsRecordsStorage.storage.store, ({ data }) =>
    data.map((node) => getUpdatedNode(node)),
  );

  const [treeData, setTreeData] = useState<UiTreeSelectProps['treeData']>(organizationsRecordsOptions);

  const { loading } = useAbstractStorage(organizationsRecordsStorage.storage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  useEffect(() => {
    setTreeData(organizationsRecordsOptions);
  }, [organizationsRecordsOptions]);

  const { fetchFx: fetchStructureStorageList } = useAbstractStorage(structureStorage.storage, {
    autoFetchAndRefetch: false,
    cancelPendingRequestOnUnmount: true,
  });

  const onLoadData = useCallback(
    ({ key, children }: { key?: Key; children?: Record<string, unknown>[] }) => {
      if (children) {
        return Promise.resolve();
      }

      return fetchStructureStorageList({
        id: String(key),
      }).then((item) => {
        setTreeData((prevTree) => {
          return prevTree ? getUpdatedTreeData(prevTree, item, key) : prevTree;
        });
      });
    },
    [setTreeData],
  );

  return (
    <UiForm.Item noStyle name={dataIndex}>
      <UiTreeSelect
        loadData={onLoadData}
        treeDataSimpleMode
        treeCheckable
        loading={loading}
        labelInValue
        treeData={treeData}
        style={{ width: '100%' }}
      />
    </UiForm.Item>
  );
};
