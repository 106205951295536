import { UiButton, UiIcon, UiTooltip, useTheme } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { GetListRowsStorage } from '@vkph/common/store/lists';
import { ListColumnFieldType, ListColumnModel } from '@vkph/common/types/models';
import SortingTableSvg from '@vkph/ui/svg/sorting-table.svg';

import { tooltipTextByListColumnType } from '../constants';

enum SortStatus {
  ASC = 'ASC',
  DESC = 'DESC',
}

const DISABLE_SORTING_TOOLTIP_TEXT = 'Нажмите, чтобы отменить сортировку';

const getTitle = (sortable: null | SortStatus, fieldType: ListColumnFieldType) => {
  if (sortable == null) {
    return tooltipTextByListColumnType[fieldType].sortable;
  }

  return sortable ? tooltipTextByListColumnType[fieldType].reverseSortable : DISABLE_SORTING_TOOLTIP_TEXT;
};

type Props = {
  column: ListColumnModel;
  listRowsStorage: GetListRowsStorage;
};

export const DynamicTableSortButton: FC<Props> = (props) => {
  const { column, listRowsStorage } = props;
  const { id, fieldType } = column;

  const [{ variables: themeVariables }] = useTheme();
  const { updateStoreEvent: updateRowsParamsEvent, store: rowsParamsStore } = listRowsStorage.paramsStore;
  const listRowsParams = useStore(rowsParamsStore);

  const sortStatus = useMemo(() => {
    const currentValue = listRowsParams.ordering?.find((value) => value.includes(id));

    if (currentValue) {
      return currentValue.startsWith('-') ? SortStatus.DESC : SortStatus.ASC;
    }

    return null;
  }, [id, listRowsParams]);

  const onSort = () => {
    const ordering = sortStatus === SortStatus.ASC ? [`-${id}`] : undefined;

    updateRowsParamsEvent({
      ...listRowsParams,
      ordering: sortStatus === null ? [id] : ordering,
    });
  };

  return (
    <UiTooltip title={getTitle(sortStatus, fieldType)} placement="top">
      <UiButton
        onClick={onSort}
        type="link-secondary"
        icon={
          <UiIcon
            width={20}
            height={20}
            component={SortingTableSvg}
            color={sortStatus ? themeVariables.colorBrand : themeVariables.colorIcon}
          />
        }
      />
    </UiTooltip>
  );
};
