import { UiTruncateMarkup, UiFlex, useSpace, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState, useRef } from 'react';

import { CalendarEventType } from '@vkph/common/types/models';

import styles from './CalendarCardTask.scss';
import { CardTaskTitleIcons } from './title-icons/CardTaskTitleIcons';

enum EventStatus {
  Default = 'default',
  Past = 'past',
  Maybe = 'maybe',
  Future = 'future',
}

const getStatus = (isUnconfirmed: boolean, isPassed: boolean, isMaybe: boolean) => {
  if (isUnconfirmed) return EventStatus.Default;
  if (isPassed) return EventStatus.Past;
  if (isMaybe) return EventStatus.Maybe;
  return EventStatus.Future;
};

const MIN_CONTENT_SHOW_WIDTH = 50;

export type CalendarCardTaskProps = {
  eventType: CalendarEventType;
  title: string;
  titleSuffix?: string;
  subtitle: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  linesCount?: number;
  isEventPassed?: boolean;
  isEventUnconfirmed?: boolean;
  isEventMaybe?: boolean;
  isLargeGap?: boolean;
  isHasPlace?: boolean;
  isHasBroadcastLink?: boolean;
  style?: React.CSSProperties;
};

export type CalendarCardTaskPropsComponent = React.FC<CalendarCardTaskProps>;

export const CalendarCardTask: CalendarCardTaskPropsComponent = (props) => {
  const {
    eventType,
    title,
    titleSuffix,
    subtitle,
    onClick,
    linesCount,
    isEventPassed,
    isEventUnconfirmed,
    isEventMaybe,
    isLargeGap,
    isHasPlace,
    isHasBroadcastLink,
    style,
    ...wrapperProps
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isHidden, setIsHidden] = useState(false);
  const { space2XS } = useSpace();

  const checkContentVisible = () => {
    if (buttonRef.current) {
      setIsHidden(buttonRef.current.clientWidth < MIN_CONTENT_SHOW_WIDTH);
    }
  };

  const isOneLine = linesCount === 1;
  const isShowIcons = isHasPlace || isHasBroadcastLink;
  const status = getStatus(Boolean(isEventUnconfirmed), Boolean(isEventPassed), Boolean(isEventMaybe));

  const ellipsis = (node: ReactNode) => {
    // @ts-expect-error TODO: ждем пока react-truncate-markup обновит типы до react 18
    const renderedNodes = node?.props?.children;

    return renderedNodes[0]?.props?.children?.length < title.length && '...';
  };

  useEffect(() => {
    checkContentVisible();
  }, [style]);

  useEffect(() => {
    window.addEventListener('resize', checkContentVisible);

    return () => {
      window.removeEventListener('resize', checkContentVisible);
    };
  }, [buttonRef.current]);

  return (
    <div
      {...wrapperProps}
      className={classNames(styles.calendarCardTask, {
        [styles.calendarCardTask_largeGap]: isLargeGap,
      })}
      style={style}
    >
      <button
        ref={buttonRef}
        className={classNames(
          styles.calendarCardTask__colorContainer,
          `${styles.calendarCardTask__colorContainer}_${eventType}-${status}`,
        )}
        type="button"
        onClick={onClick}
      >
        {!isHidden && isOneLine && (
          <UiFlex align="center">
            <UiTypography.Text
              className={classNames(
                styles.calendarCardTask__contentContainer,
                styles.calendarCardTask__contentContainer_oneLine,
              )}
            >
              <UiTruncateMarkup lines={1} truncate>
                <span className={styles.calendarCardTask__timeOneLine}>{title}</span>
                {subtitle}
              </UiTruncateMarkup>
            </UiTypography.Text>
            {isShowIcons && (
              <CardTaskTitleIcons isHasPlace={isHasPlace} isHasBroadcastLink={isHasBroadcastLink} />
            )}
          </UiFlex>
        )}
        {!isHidden && !isOneLine && (
          <div className={styles.calendarCardTask__contentContainer}>
            <UiFlex gap={space2XS}>
              <UiTruncateMarkup truncate ellipsis={ellipsis} lines={1} lineHeight="14px">
                <div className={classNames(styles.calendarCardTask__time)}>{title}</div>

                {Boolean(titleSuffix?.length) && (
                  <UiTruncateMarkup.Atom>
                    <span className={classNames(styles.calendarCardTask__time)}>{titleSuffix}</span>
                  </UiTruncateMarkup.Atom>
                )}
              </UiTruncateMarkup>
              {isShowIcons && (
                <CardTaskTitleIcons isHasPlace={isHasPlace} isHasBroadcastLink={isHasBroadcastLink} />
              )}
            </UiFlex>
            <UiTruncateMarkup truncate lines={linesCount && linesCount - 1} lineHeight="14px">
              {subtitle}
            </UiTruncateMarkup>
          </div>
        )}
      </button>
    </div>
  );
};
