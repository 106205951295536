import { getModalStepsForSingleTitle, UiButton, UiForm, UiModal } from '@vkph/ui';
import React, { CSSProperties, FC, useMemo } from 'react';

import { getProfileListOptionsStorage } from '@vkph/common/store';
import { UserModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';

import { SearchUserField } from '../../../../search-user-field';
import { DynamicTableCellContentProps } from '../../types';

interface Props
  extends Pick<DynamicTableCellContentProps<UserModel | undefined>, 'dataIndex' | 'onFinish' | 'value'> {
  onModalClose?: () => void;
}

export const UserCellModalForm: FC<Props> = (props) => {
  const { onFinish, value, dataIndex, onModalClose } = props;
  const [form] = UiForm.useForm();
  const { storage: profileListOptionsStorage } = useMemo(getProfileListOptionsStorage, []);

  const formItemStyles: CSSProperties = { width: '100%', marginBottom: 0 };

  const initialValues = {
    [dataIndex]: value && { id: value.id, name: getFullNameWithoutPatronymic(value) },
  };

  const onSubmit = () => {
    form.submit();
    onModalClose?.();
  };

  return (
    <UiForm form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Добавить пользователей')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm.Item label="Выбрать пользователя" name={dataIndex} style={formItemStyles}>
          <SearchUserField storage={profileListOptionsStorage} />
        </UiForm.Item>
      </UiModal.Content>
      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiForm.Item noStyle shouldUpdate>
            {({ isFieldsTouched }) => (
              <UiButton type="primary" size="large" disabled={!isFieldsTouched()} onClick={onSubmit}>
                Сохранить
              </UiButton>
            )}
          </UiForm.Item>
          <UiButton type="tertiary" size="large" onClick={onModalClose}>
            Отмена
          </UiButton>
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
