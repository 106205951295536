import { UiIcon, useSpace } from '@vkph/ui';
import React, { FC } from 'react';

import LocationSvg from '@vkph/ui/svg/location.svg';
import VideoSvg from '@vkph/ui/svg/video.svg';

import { CalendarCardTaskProps } from '../CalendarCardTask';

type Props = Pick<CalendarCardTaskProps, 'isHasPlace' | 'isHasBroadcastLink'>;

export const CardTaskTitleIcons: FC<Props> = (props) => {
  const { isHasPlace, isHasBroadcastLink } = props;
  const { space2XS } = useSpace();

  return (
    <div>
      {isHasPlace && (
        <UiIcon style={{ paddingRight: space2XS }} component={LocationSvg} width={12} height={12} />
      )}
      {isHasBroadcastLink && <UiIcon component={VideoSvg} width={12} height={12} />}
    </div>
  );
};
