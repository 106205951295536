import {
  UiButton,
  UiFlex,
  UiForm,
  UiIcon,
  UiModal,
  UiModalTypes,
  UiTooltip,
  UiTree,
  UiTreeKey,
  UiTreeNode,
  UiTypography,
  getModalStepsForSingleTitle,
  getUpdatedTreeData,
  usePaddingStyle,
  useSpace,
  useToggle,
} from '@vkph/ui';
import { notification } from 'antd';
import { useStore } from 'effector-react';
import React, { FC, Key, useCallback, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  createUpdateFileStorageEntryEffect,
  getFileStorageListStorage,
  getFilesUploadStorage,
} from '@vkph/common/store';
import { FileStorageEntryId } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import FileAddSvg from '@vkph/ui/svg/file-add.svg';
import FolderSvg from '@vkph/ui/svg/folder-files.svg';

import { FileCellUploadForm } from '../file-cell-upload-form/FileCellUploadForm';
import { FileTreeNode } from '../file-tree-node/FileTreeNode';
import { FileUploadArea } from '../file-upload-area/FileUploadArea';

interface Props {
  rootId: FileStorageEntryId;
  isOpen: boolean;
  selectedKey: Key | null;
  title?: string;
  isShowUploadArea: boolean;
  closeModal: () => void;
  onSelect: (selectedKeys: UiTreeKey[]) => void;
  onFinish: () => void;
  onUploadFile: () => void;
}

export const FileCellForm: FC<Props> = (props) => {
  const {
    isOpen,
    rootId,
    isShowUploadArea,
    title,
    selectedKey,
    closeModal,
    onSelect,
    onFinish,
    onUploadFile,
  } = props;

  const filesUploadStorage = useMemo(getFilesUploadStorage, []);
  const { storage: activeFileStorageListStorage } = useMemo(() => getFileStorageListStorage(rootId), []);

  const preloadedFiles = useStore(filesUploadStorage.storage.store);

  const { removeFilesEvent } = filesUploadStorage.storage;

  const { fetchFx: fetchFileStorageList } = useAbstractStorage(activeFileStorageListStorage, {
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const [form] = UiForm.useForm();
  const { spaceXS, spaceS, spaceM, spaceXL } = useSpace();
  const stylesModalContent = usePaddingStyle([spaceM, spaceXL]);
  const [isOpenUploadForm, toggleOpenUploadForm] = useToggle([false, true]);
  const [parentId, setParentId] = useState(rootId);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([rootId]);

  const addFile = (id: string) => {
    setParentId(id);
    toggleOpenUploadForm();
  };

  const [treeData, setTreeData] = useState<UiTreeNode[]>([
    {
      title: (
        <UiFlex style={{ width: '100%' }} justify="space-between" align="center">
          <UiFlex gap={spaceS} align="center">
            <UiIcon component={FolderSvg} width={32} height={32} />

            <UiTypography.Text>{title}</UiTypography.Text>
          </UiFlex>
          <UiTooltip title="Добавить файл" placement="left">
            <UiButton
              icon={
                <UiIcon
                  component={FileAddSvg}
                  onClick={() => {
                    addFile(rootId);
                  }}
                  width={20}
                  height={20}
                />
              }
            />
          </UiTooltip>
        </UiFlex>
      ),
      key: rootId,
      isLeaf: false,
    },
  ]);

  const onLoadData = useCallback(({ key, children }: UiTreeNode): Promise<void> => {
    if (children) {
      return Promise.resolve();
    }

    return fetchFileStorageList({
      entryId: String(key),
      pageSize: 1000,
      pageNumber: 1,
    }).then(({ items }) => {
      setTreeData((origin) => {
        return getUpdatedTreeData(
          origin,
          key,
          items.map((data) => ({
            key: data.id,
            title: <FileTreeNode id={String(key)} data={data} addFile={addFile} />,
            isLeaf: data.foldersCount === 0 && data.filesCount === 0,
          })),
        );
      });
    });
  }, []);

  const preloadedFile = useMemo(() => {
    if (preloadedFiles && preloadedFiles.length) {
      return {
        ...preloadedFiles[0],
        fileData: {
          ...preloadedFiles[0].fileData,
          name: preloadedFiles[0].fileData.name,
          status: preloadedFiles[0].status,
          error: preloadedFiles[0].errorMessage,
        },
      };
    }

    return null;
  }, [preloadedFiles]);

  const onRemoveFile = useCallback(() => {
    if (preloadedFile) {
      removeFilesEvent([preloadedFile.key]);
    }
  }, [removeFilesEvent, preloadedFile]);

  const onLoadFileToStorage = () => {
    const params = {
      name: preloadedFile?.fileData?.name,
      id: preloadedFile ? preloadedFile.fileData.uid : parentId,
      parent: parentId,
    };

    createUpdateFileStorageEntryEffect(params)
      .then(() => {
        notification.success({ message: 'Файл успешно создан' });

        onLoadData({ key: parentId }).then(() => {
          onRemoveFile();

          if (!isShowUploadArea) {
            toggleOpenUploadForm();
          } else {
            onUploadFile();
          }
        });
      })
      .catch((e) => notification.error({ message: getErrorResponseMessage(e, 'Ошибка создания файла') }));
  };

  const onSubmit = () => {
    if (isShowUploadArea) {
      onLoadFileToStorage();
    } else {
      form.submit();
    }
  };

  return (
    <>
      <FileCellUploadForm
        filesUploadStorage={filesUploadStorage}
        preloadedFile={preloadedFile}
        isOpen={isOpenUploadForm}
        onLoadFileToStorage={onLoadFileToStorage}
        parentId={parentId}
        closeModal={toggleOpenUploadForm}
      />
      <UiModal type={UiModalTypes.Medium} isOpen={isOpen} onClose={closeModal}>
        <UiForm form={form} onFinish={onFinish}>
          <UiModal.Header hasBottomBorder>
            <UiModal.Header.Title steps={getModalStepsForSingleTitle('Загрузка файлов')} />
          </UiModal.Header>
          <UiModal.Content style={stylesModalContent}>
            <UiTypography.Text type="secondary">Файловое хранилище</UiTypography.Text>
            <UiTree
              rootStyle={{
                paddingTop: spaceXS,
                paddingBottom: 0,
              }}
              emptyPaddingLeftNode
              onSelect={onSelect}
              defaultExpandParent
              expandedKeys={expandedKeys}
              onExpand={setExpandedKeys}
              treeData={treeData}
              loadData={onLoadData}
              showIcon
            />
            {isShowUploadArea && (
              <FileUploadArea filesUploadStorage={filesUploadStorage} preloadedFile={preloadedFile} />
            )}
          </UiModal.Content>
          <UiModal.Footer hasTopBorder>
            <UiModal.Footer.Buttons>
              <UiButton
                label={isShowUploadArea ? 'Сохранить' : 'Выбрать'}
                disabled={isShowUploadArea ? !preloadedFile : !selectedKey}
                size="large"
                type="primary"
                onClick={onSubmit}
              />
              <UiButton
                label={isShowUploadArea ? 'Отмена' : 'Закрыть'}
                onClick={closeModal}
                size="large"
                type="tertiary"
              />
            </UiModal.Footer.Buttons>
          </UiModal.Footer>
        </UiForm>
      </UiModal>
    </>
  );
};
