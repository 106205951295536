import { UiButton, UiIcon, UiTooltip, UiTruncateMarkup, UiTypography, useSpace, UiFlex } from '@vkph/ui';
import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';

import { ListColumnFieldType } from '@vkph/common/types/models';

import { listColumnTypeFields } from '../../constants';

type Props = {
  fieldType: ListColumnFieldType;
  label?: string;
  isCreating?: boolean;
};

export const TableHeaderCellTitle: FC<PropsWithChildren<Props>> = (props) => {
  const { fieldType, label, isCreating, children } = props;
  const { icon, label: defaultLabel, description } = listColumnTypeFields[fieldType];
  const { spaceXS } = useSpace();
  const cellLabel = label || defaultLabel;
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isCreating) {
      containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isCreating]);

  return (
    <UiFlex gap={spaceXS} align="center" ref={containerRef}>
      <UiTooltip title={description}>
        <UiButton
          size="small"
          type="link-secondary"
          icon={<UiIcon width={20} height={20} component={icon} />}
        />
      </UiTooltip>

      <UiTypography.Text strong>
        <UiTruncateMarkup truncate lines={1} tooltipProps={{ title: cellLabel }}>
          {cellLabel}
        </UiTruncateMarkup>
      </UiTypography.Text>

      {children}
    </UiFlex>
  );
};
