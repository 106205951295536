import { UiSpace, UiTypography, UiCollapse, UiCollapsePanelProps, UiCollapseProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { useLocalStorage } from '@vkph/common/hooks';

import styles from './CollapseStored.scss';

export interface CollapseStoredProps extends Omit<UiCollapsePanelProps, 'key'> {
  name: string;
  initialValue?: boolean;
}

export const CollapseStored: FC<PropsWithChildren<CollapseStoredProps>> = (props) => {
  const { name, initialValue, children, header, extra } = props;
  const localStorageKey = `CollapseStored_${name}`;
  const [isExpanded, setIsExpanded] = useLocalStorage(localStorageKey, initialValue);
  const onChange = () => setIsExpanded(!isExpanded);

  const items: UiCollapseProps['items'] = [
    {
      key: localStorageKey,
      label: (
        <UiSpace size="small">
          {extra}
          <UiTypography.Text strong>{header}</UiTypography.Text>
        </UiSpace>
      ),
      children,
    },
  ];

  return (
    <UiCollapse
      ghost
      defaultActiveKey={isExpanded ? localStorageKey : undefined}
      onChange={onChange}
      expandIconPosition="end"
      items={items}
      className={styles.collapseStored}
    >
      {children}
    </UiCollapse>
  );
};
