import { UiTruncateMarkup, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import { format, differenceInMinutes } from 'date-fns';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage, useLongreadLink } from '@vkph/common/hooks';
import { GetCalendarEventsStorage } from '@vkph/common/store/calendar';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { LongreadTypes } from '@vkph/common/types';
import { CalendarEventModel, CalendarEventType, CalendarUserStatus } from '@vkph/common/types/models';

import { AppearanceTooltip } from '../../tooltip/AppearanceTooltip';
import styles from './MonthEvent.scss';

type MonthEventProps = {
  event: CalendarEventModel;
  isLastWeekDay?: boolean;
  columnDate?: Date;
  isSameTime?: boolean;
  isFirstDayEvent?: boolean;
  isLastDayEvent?: boolean;
  eventsStorage: GetCalendarEventsStorage;
};

enum EventStatus {
  Default = 'default',
  Past = 'past',
  Maybe = 'maybe',
  Future = 'future',
}

const getStatus = (isUnconfirmed: boolean, isPassed: boolean, isMaybe: boolean) => {
  if (isUnconfirmed) return EventStatus.Default;
  if (isPassed) return EventStatus.Past;
  if (isMaybe) return EventStatus.Maybe;
  return EventStatus.Future;
};

export const MonthEvent: FC<MonthEventProps> = (props) => {
  const { event, isSameTime, isLastDayEvent, isLastWeekDay, isFirstDayEvent, columnDate, eventsStorage } =
    props;
  const { storage } = eventsStorage;
  const { refetchWithLastParams } = useAbstractStorage(storage);

  const navigate = useNavigate();
  const { to: linkToEventLongread } = useLongreadLink({ type: LongreadTypes.Events, id: event.id });

  const { since, till, summary, eventType, myStatus } = event;
  const sinceDate = new Date(since);
  const tillDate = new Date(till);
  const isEventPassed = differenceInMinutes(Date.now(), tillDate) >= 0;
  const isEventUnconfirmed = myStatus === CalendarUserStatus.NeedAction;
  const isEventMaybe = myStatus === CalendarUserStatus.Maybe;

  const timeMask = 'HH:mm';
  const timeStartString = format(sinceDate, timeMask);

  const status = getStatus(isEventUnconfirmed, isEventPassed, isEventMaybe);

  const openEventClickHandler: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();

    if (eventType === CalendarEventType.Activity) {
      navigate(linkToEventLongread);
    } else {
      openGlobalModal(GlobalModalNames.CalendarEventDetail, { event, onSuccess: refetchWithLastParams });
    }
  };

  const contentTitleEvent = (
    <UiTypography.Text className={styles.monthEvent__title} strong>
      <UiTruncateMarkup truncate lines={1} className={styles.monthEvent__contentContainer}>
        <span className={styles.monthEvent__time}>{timeStartString}</span>
        {summary}
      </UiTruncateMarkup>
    </UiTypography.Text>
  );

  return (
    <AppearanceTooltip isLastWeekDay={isLastWeekDay} event={event} columnDate={columnDate}>
      <button
        className={classNames(styles.monthEvent, `${styles.monthEvent}_${event.eventType}-${status}`, {
          [styles.monthEvent_same]: isSameTime && !isLastDayEvent && !isLastWeekDay,
        })}
        type="button"
        onClick={openEventClickHandler}
      >
        {isFirstDayEvent && isSameTime && contentTitleEvent}
        {!isSameTime && contentTitleEvent}
      </button>
    </AppearanceTooltip>
  );
};
