import { UiFlex, UiForm, UiIcon, UiInput, UiTypography, useSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { regexpPatterns, requiredRule, validateMaxValue, validateMinValue } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import RemoveSvg from '@vkph/ui/svg/remove.svg';

const rangeRules = [
  {
    message: 'Пожалуйста, введите числовое значение',
    pattern: regexpPatterns.number,
  },
  validateMinValue(0),
  validateMaxValue(1000000),
];

export const NumericFormFields: FC = () => {
  const { spaceXL, spaceM, space2XS } = useSpace();

  return (
    <UiFlex vertical>
      <UiFlex vertical gap={space2XS}>
        <UiTypography.Text type="secondary">Диапазон значений</UiTypography.Text>
        <UiFlex gap={spaceM} align="center">
          <UiForm.Item rules={rangeRules} name="minValue">
            <UiInput placeholder="От 0" />
          </UiForm.Item>
          <UiIcon
            component={RemoveSvg}
            color={variables.themePalette.colorIcon}
            style={{ marginBottom: spaceXL }}
          />
          <UiForm.Item name="maxValue" rules={rangeRules}>
            <UiInput placeholder="До 1 000 000" />
          </UiForm.Item>
        </UiFlex>
      </UiFlex>
      <UiForm.Item
        name="defaultCellValue"
        label="Данные ячейки для вставки во все ячейки столбца"
        rules={[...rangeRules, requiredRule]}
        style={{ marginBottom: 0 }}
      >
        <UiInput />
      </UiForm.Item>
    </UiFlex>
  );
};
