import { format, differenceInMinutes } from 'date-fns';
import { useStore } from 'effector-react';
import React, { useState, useEffect, FC } from 'react';

import { GetCalendarSelectedStorage } from '@vkph/common/store/calendar';
import { convertMinutesToPx } from '@vkph/common/utils';

import { HIDDEN_HOUR } from '../constants';
import styles from './AppearanceTimeline.scss';

type Props = {
  selectedStorage: GetCalendarSelectedStorage;
};

export const AppearanceTimeline: FC<Props> = (props) => {
  const { selectedStorage } = props;
  const columnHeadingHeight = 72;
  const columnDivisionHeight = 42;
  const borderHeight = 1;

  const { isHiddenEarlyTimeSectionStore } = selectedStorage;
  const isHiddenEarlyTimeSection = useStore(isHiddenEarlyTimeSectionStore);

  const hourStart = isHiddenEarlyTimeSection ? HIDDEN_HOUR : 0;

  const [dateNow, setDateNow] = useState(new Date());

  const tick = () => setDateNow(() => new Date());

  useEffect(() => {
    const timerInterval = setInterval(tick, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const timeStart = new Date();

  timeStart.setHours(hourStart);
  timeStart.setMinutes(0);
  timeStart.setSeconds(0);

  const diffMinutes = differenceInMinutes(dateNow, timeStart);
  const topMinutes = diffMinutes < 0 ? 0 : diffMinutes;

  const isBeforeHiddenHour = dateNow.getHours() < HIDDEN_HOUR;
  const columnDivisionCompensationHeight = isBeforeHiddenHour ? 0 : columnDivisionHeight;
  const compensationHeight = columnHeadingHeight + columnDivisionCompensationHeight - borderHeight;

  const getTopPosition = () => {
    return convertMinutesToPx(topMinutes, compensationHeight);
  };

  return (
    <div className={styles.appearanceTimeline} style={{ top: getTopPosition() }}>
      <div className={styles.appearanceTimeline__time}>{format(dateNow, 'HH:mm')}</div>
      <div className={styles.appearanceTimeline__line} />
    </div>
  );
};
