import { UiForm, UiTreeSelect } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getProfilePositionListStorage } from '@vkph/common/store';

type Props = {
  dataIndex: string;
};

export const DictionaryPosition: FC<Props> = (props) => {
  const { dataIndex } = props;

  const profilePositionListStorage = useMemo(getProfilePositionListStorage, []);

  const { loading } = useAbstractStorage(profilePositionListStorage.storage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const treeData = useStoreMap(profilePositionListStorage.storage.store, ({ data }) =>
    data.map((item) => ({
      value: item.id,
      title: item.name,
    })),
  );

  return (
    <UiForm.Item noStyle name={dataIndex}>
      {!loading && <UiTreeSelect labelInValue treeCheckable treeData={treeData} style={{ width: '100%' }} />}
    </UiForm.Item>
  );
};
