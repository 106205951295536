import {
  UiButton,
  UiDropdown,
  UiFlex,
  UiIcon,
  UiInput,
  UiPopover,
  UiSpace,
  UiTypography,
  usePaddingStyle,
  useSpace,
  useToggle,
  notification,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { ChangeEvent, FC, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { GetListColumnsStorage } from '@vkph/common/store/lists';
import { ListColumnModel, ListId } from '@vkph/common/types/models';
import AddSvg from '@vkph/ui/svg/add.svg';
import DropDownSvg from '@vkph/ui/svg/drop-down.svg';
import DropUpSvg from '@vkph/ui/svg/drop-up.svg';
import MeatballSvg from '@vkph/ui/svg/meatball.svg';

import { GetColumnDropdownActionItems, GetCreateColumnItems } from '../DynamicTable';
import { MAX_COLUMNS_COUNT } from '../constants';
import { ListCellItem } from './cell-item/ListCellItem';

interface Props {
  listId: ListId;
  listColumnsStorage: GetListColumnsStorage;
  getDropdownActionItems: GetColumnDropdownActionItems;
  getCreateColumnItems: GetCreateColumnItems;
  containerRef?: RefObject<HTMLDivElement>;
  actionButton?: ReactNode;
}

export const ListSettings: FC<Props> = (props) => {
  const { listId, containerRef, listColumnsStorage, getDropdownActionItems, getCreateColumnItems } = props;
  const [isOpen, toggleIsOpen] = useToggle([false, true]);
  const [search, setSearch] = useState('');
  const { spaceM, spaceXS, spaceXL } = useSpace();
  const createButtonPadding = usePaddingStyle([spaceM, 0]);
  const titlePadding = usePaddingStyle([spaceXS, 0]);
  const { padding: listPadding } = usePaddingStyle([0, spaceXL]);

  const { toggleVisibleListColumnEffect } = listColumnsStorage;
  const { data: columnsData } = useStore(listColumnsStorage.storage.store);

  const filteredColumnsData = useMemo(() => {
    return columnsData.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));
  }, [search, columnsData]);

  const { visibleColumns, hiddenColumns } = filteredColumnsData.reduce<{
    visibleColumns: ListColumnModel[];
    hiddenColumns: ListColumnModel[];
  }>(
    (acc, item) => {
      if (item.isVisible) {
        acc.visibleColumns.push(item);
      } else {
        acc.hiddenColumns.push(item);
      }

      return acc;
    },
    { visibleColumns: [], hiddenColumns: [] },
  );

  const onSearchDebounced = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    300,
  );

  const onToggleVisibleColumn = (column: ListColumnModel) => {
    const { id: columnId, isVisible } = column;

    return toggleVisibleListColumnEffect({ id: listId, columnId }).then(() => {
      notification.success({
        message: isVisible ? 'Столбец успешно скрыт' : 'Столбец показан',
        ...(isVisible && {
          description: 'Столбец скрыт в списке и значения в нём стали необязательными',
        }),
      });
    });
  };

  useEffect(() => {
    setSearch('');
  }, [isOpen]);

  const isCreateColumnDisabled = columnsData.length === MAX_COLUMNS_COUNT;

  const ListCellItems: FC<{ columns: ListColumnModel[] }> = (args) => {
    const { columns } = args;

    return columns.map((column, index) => (
      <ListCellItem
        key={column.id}
        column={column}
        onVisibleToggle={() => onToggleVisibleColumn(column)}
        extra={
          column.isVisible && (
            <UiDropdown
              trigger={['click']}
              placement="bottomRight"
              getPopupContainer={() => containerRef?.current || document.body}
              dropdownRender={(menu) => (
                <UiButton.Decorator onClick={() => toggleIsOpen(false)}>{menu}</UiButton.Decorator>
              )}
              menu={{
                items: getDropdownActionItems(column, index),
              }}
            >
              <UiButton
                icon={<UiIcon width={20} height={20} component={MeatballSvg} />}
                type="link-secondary"
              />
            </UiDropdown>
          )
        }
      />
    ));
  };

  return (
    <UiPopover
      open={isOpen}
      onOpenChange={toggleIsOpen}
      trigger="click"
      placement="bottomRight"
      destroyTooltipOnHide
      getPopupContainer={() => containerRef?.current || document.body}
      arrow={false}
      overlayInnerStyle={{ padding: 0 }}
      content={
        <UiFlex vertical style={{ width: '460px', maxHeight: '524px' }}>
          <UiFlex vertical gap={spaceM} style={{ padding: spaceXL }}>
            <UiTypography.Title level={3}>Столбцы</UiTypography.Title>
            <UiInput.Search allowClear placeholder="Поиск" onChange={onSearchDebounced} />
          </UiFlex>
          <UiFlex
            vertical
            gap={spaceXS}
            style={{ padding: listPadding, marginBottom: spaceM, flex: 1, overflow: 'auto' }}
          >
            <UiFlex vertical>
              <UiTypography.Text strong style={titlePadding}>
                Показано в списке
              </UiTypography.Text>
              <UiFlex vertical>
                <ListCellItems columns={visibleColumns} />
              </UiFlex>
              <UiSpace style={createButtonPadding}>
                <UiDropdown
                  trigger={['click']}
                  getPopupContainer={() => containerRef?.current || document.body}
                  disabled={isCreateColumnDisabled}
                  dropdownRender={(menu) => (
                    <UiButton.Decorator onClick={() => toggleIsOpen(false)}>{menu}</UiButton.Decorator>
                  )}
                  menu={{
                    items: getCreateColumnItems({ showTooltip: false }),
                  }}
                >
                  <UiButton
                    type="link"
                    icon={<UiIcon width={20} height={20} component={AddSvg} />}
                    label="Добавить столбец"
                  />
                </UiDropdown>
              </UiSpace>
            </UiFlex>
            {hiddenColumns.length > 0 && (
              <UiFlex vertical>
                <UiTypography.Text strong style={titlePadding}>
                  Скрыто в списке
                </UiTypography.Text>
                <UiFlex vertical>
                  <ListCellItems columns={hiddenColumns} />
                </UiFlex>
              </UiFlex>
            )}
          </UiFlex>
        </UiFlex>
      }
    >
      <UiButton type="primary" size="large">
        <UiFlex align="center" gap={spaceXS}>
          Настроить список
          <UiIcon width={20} height={20} component={isOpen ? DropUpSvg : DropDownSvg} />
        </UiFlex>
      </UiButton>
    </UiPopover>
  );
};
