import { useBreakpoint, UiSkeleton } from '@vkph/ui';
import React, { FC, Suspense } from 'react';

import { FileStorageListEntryModel } from '@vkph/common/types/models';

import { FileStorageListItemSelect } from '..';
import { ActionsDropdownItem } from '../../actions-dropdown';
import { FileProps } from '../../file';

type FileStorageItemFunction = (item: FileStorageListEntryModel) => void;

export interface FileStorageListItemProps extends Pick<FileProps, 'direction'> {
  fileSelect?: FileStorageListItemSelect;
  onClick: FileStorageItemFunction;
  file: FileStorageListEntryModel;
  onFileSelect?: FileStorageItemFunction;
  getFileActions: (file: FileStorageListEntryModel) => ActionsDropdownItem[];
}

const FileStorageListItemDesktop = React.lazy(() => import('./desktop'));
const FileStorageListItemMobile = React.lazy(() => import('./mobile'));

export const FileStorageListItem: FC<FileStorageListItemProps> = (props) => {
  const { md: isLayoutMedium } = useBreakpoint();

  if (isLayoutMedium) {
    return (
      <Suspense fallback={<UiSkeleton loading height={40} />}>
        <FileStorageListItemDesktop {...props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<UiSkeleton loading height={40} />}>
      <FileStorageListItemMobile {...props} />
    </Suspense>
  );
};
