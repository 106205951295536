import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { UiUser, UiUserInfoProps } from '../user';
import styles from './LinkListItem.scss';
import { LinkListItemSkeleton } from './skeleton/LinkListItemSkeleton';

export interface LinkListItemProps extends Omit<UiUserInfoProps, 'size'> {
  size?: number;
  suffixedChildren?: ReactNode;
}

export interface LinkListItemInterface extends FC<LinkListItemProps> {
  Skeleton: typeof LinkListItemSkeleton;
}

// TODO B2BCORE-9773 разобраться и удалить
export const LinkListItem: LinkListItemInterface = (props) => {
  const { className, avatarProps, size = 'middle', suffixedChildren, ...restProps } = props;

  return (
    <div className={classNames(styles.linkListItem, className)}>
      <UiUser.Info
        avatarProps={{ ...avatarProps, size }}
        className={styles.linkListItem__info}
        {...restProps}
      />
      <span className={styles.linkListItem__actions}>{suffixedChildren}</span>
    </div>
  );
};

LinkListItem.Skeleton = LinkListItemSkeleton;
