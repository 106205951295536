import { animated } from '@react-spring/web';
import { UiTruncateMarkup } from '@vkph/ui';
import React, { useContext, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import {
  getDateRangeByInterval,
  getFormattedTimeRange,
  LAYOUT_HOUR_IN_PX,
  roundToGrid,
} from '@vkph/common/utils';

import { CalendarAppearancePermissionsContext } from '../../calendar-appearance';
import styles from './CalendarCreatingSection.scss';

const BORDER_COMPENSATION = 4;

type CalendarCreatingSectionProps = {
  children: React.ReactNode;
  onDragEnd: (x: number, till: number) => void;
  sinceDate: Date;
  snapWidth?: number;
};

export type CalendarCreatingSectionPropsComponent = React.FC<CalendarCreatingSectionProps>;

export const CalendarCreatingSection: CalendarCreatingSectionPropsComponent = (props) => {
  const { children, snapWidth = 1, onDragEnd, sinceDate } = props;
  const { readOnly } = useContext(CalendarAppearancePermissionsContext);

  const snapToGrid = (x: number) => roundToGrid(x, snapWidth);
  const subtitle = 'Без названия';

  const wrapperRef = useRef<HTMLDivElement>(null);
  const getWrapperTopY = () =>
    (wrapperRef.current?.getBoundingClientRect()?.y || 0) + (wrapperRef.current?.scrollTop || 0);

  const initialValues = { x: 0, width: 0 };

  const [{ x, width }, setSelection] = useSpring<{ x: number; width: number }>(() => initialValues, []);
  const [title, setTitle] = useState('');

  const bindDragGesture = useGesture(
    {
      onDragStart: ({ xy: [, oy], event }) => {
        event.preventDefault();
        setSelection.set({
          x: snapToGrid(oy - getWrapperTopY()),
        });
      },
      onDrag: ({ movement: [, oy], initial }) => {
        const snappedY = snapToGrid(oy);
        const snappedYBelowZero = snappedY + snapToGrid(initial[1] - getWrapperTopY());
        const widthBelowZero = -(snappedY + BORDER_COMPENSATION);
        let newX;
        let newWidth;

        if (oy < 0) {
          newX = snappedYBelowZero;
          newWidth = widthBelowZero;
        } else {
          newX = snapToGrid(x.get());
          newWidth = snappedY;
        }

        setSelection.start({ x: newX, width: newWidth });

        const { since, till } = getDateRangeByInterval({
          sinceDate,
          start: snapToGrid(newX),
          duration: snapToGrid(newWidth),
          pxInHour: LAYOUT_HOUR_IN_PX + 1,
        });
        const currentTitle = getFormattedTimeRange(since, till);

        if (currentTitle !== title) {
          setTitle(currentTitle);
        }
      },
      onDragEnd: ({ movement: [, oy], initial }) => {
        const snappedY = snapToGrid(oy);
        const snappedYBelowZero = snappedY + snapToGrid(initial[1] - getWrapperTopY());
        let newX;
        let newWidth;

        if (oy < 0) {
          newX = snappedYBelowZero;
          newWidth = -snappedY;
        } else {
          newX = snapToGrid(x.get());
          newWidth = snappedY;
        }

        onDragEnd(newX, newWidth);
        setSelection.set(initialValues);
      },
    },
    {
      drag: {
        // TODO: можно использовать 'x' для горизонтального ряда
        axis: 'y',
      },
    },
  );

  return (
    <animated.div
      ref={wrapperRef}
      {...(!readOnly && bindDragGesture())}
      className={styles.calendarCreatingSection}
    >
      {/* TODO: вынести отдельно в Calendar.SectionEvent */}
      <animated.div className={styles.calendarCreatingSection__event} style={{ y: x, height: width }}>
        <div className={styles.calendarCardTask__contentContainer}>
          <UiTruncateMarkup truncate lines={1}>
            <span className={styles.calendarCreatingSection__title}>{title}</span>
          </UiTruncateMarkup>
          <UiTruncateMarkup truncate lines={1}>
            <span className={styles.calendarCreatingSection__title}>{subtitle}</span>
          </UiTruncateMarkup>
        </div>
      </animated.div>
      {children}
    </animated.div>
  );
};
