import { MentionFeed } from '@ckeditor/ckeditor5-mention';
import { useStore } from 'effector-react';
import { useCallback, useMemo, useRef } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getProfilesListStorage } from '@vkph/common/store/profile';
import { UserAuthorModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames } from '@vkph/common/utils';

import {
  CkEditorConfig,
  editorMentionFeedConfig,
  EditorMentionType,
  EditorSearchMentionsHandler,
  getEditorMentionFeed,
  UserMentionFeedItem,
} from '../components/editor';

const mapUsersToMention = (users: UserAuthorModel[]): UserMentionFeedItem[] => {
  const { marker } = editorMentionFeedConfig[EditorMentionType.User];

  return users.map((user) => {
    const name = getFullNameWithoutPatronymic(user.fullName);

    return {
      id: `${marker}${name}`,
      userId: user.id,
      name,
      avatar: user.avatar,
      text: name,
      link: `${marker}${getRoutePath(RouteNames.Profile, { id: user.id })}`,
    };
  });
};

const PAGE_SIZE = 100;

export const useEditorMentionsConfig = (mentionsToInclude: EditorMentionType[]) => {
  const profileListStorage = useMemo(getProfilesListStorage, []);
  const { fetchFx: fetchProfileList, cancel: cancelFetchProfileList } = useAbstractStorage(
    profileListStorage.storage,
    {
      cancelPendingRequestOnUnmount: true,
      resetStoreOnUnmount: true,
    },
  );

  const {
    pagination: { count: total },
  } = useStore(profileListStorage.storage.store);

  const ref = useRef<boolean>(false);

  ref.current = Boolean(total && total > PAGE_SIZE);

  const onSearchUserMentions = useCallback<EditorSearchMentionsHandler<UserMentionFeedItem>>(
    (search: string) => {
      cancelFetchProfileList();

      return fetchProfileList({ search, pageSize: PAGE_SIZE, isActive: true, skipEmptyName: true }).then(
        ({ items }) => mapUsersToMention(items),
      );
    },
    [cancelFetchProfileList, fetchProfileList],
  );

  const mentionFeeds = useMemo<Record<EditorMentionType, MentionFeed>>(() => {
    return {
      [EditorMentionType.User]: getEditorMentionFeed<UserMentionFeedItem>({
        type: EditorMentionType.User,
        onSearchMentions: onSearchUserMentions,
      }),
    };
  }, [onSearchUserMentions]);

  const editorMentionsConfig = useMemo<Partial<CkEditorConfig>>(() => {
    return {
      mention: {
        feeds: mentionsToInclude.map((type) => mentionFeeds[type]),
        hasMore: () => {
          return ref.current;
        },
      },
    };
  }, [mentionsToInclude, mentionFeeds]);

  return { editorMentionsConfig };
};
