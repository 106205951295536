import { eachDayOfInterval } from 'date-fns';
import { useStore } from 'effector-react';
import React, { useMemo, FC } from 'react';

import {
  appearanceDatesStorage,
  GetCalendarEventsStorage,
  GetCalendarSelectedStorage,
} from '@vkph/common/store/calendar';
import { NotificationPayloads, NotificationTypes, useNotificationEvent } from '@vkph/common/utils';

import { AppearanceColumn } from '../column/AppearanceColumn';
import { AppearanceTimeline } from '../timeline/AppearanceTimeline';
import styles from './AppearanceWeek.scss';

type Props = {
  eventsStorage: GetCalendarEventsStorage;
  selectedStorage: GetCalendarSelectedStorage;
};

export const AppearanceWeek: FC<Props> = (props) => {
  const { eventsStorage, selectedStorage } = props;
  const { sinceDate, tillDate } = useStore(appearanceDatesStorage.store);
  const { addNotificationEvent } = eventsStorage;
  const daysOfWeek = eachDayOfInterval({ start: sinceDate, end: tillDate });

  const notificationListener = (newEvent: NotificationPayloads[NotificationTypes.CalendarEventCreated]) => {
    addNotificationEvent(newEvent.event);
  };

  useNotificationEvent(NotificationTypes.CalendarEventCreated, notificationListener);

  const weekColumnsEls = useMemo(() => {
    return daysOfWeek?.map((columnDate, index, array) => (
      <AppearanceColumn
        key={`${columnDate}`}
        eventsStorage={eventsStorage}
        selectedStorage={selectedStorage}
        className={styles.appearanceWeek__column}
        columnDate={columnDate}
        isLastWeekDay={index === array.length - 1}
      />
    ));
  }, [daysOfWeek]);

  return (
    <div className={styles.appearanceWeek}>
      <AppearanceColumn eventsStorage={eventsStorage} selectedStorage={selectedStorage} isSidebar />
      <div className={styles.appearanceWeek__contentColumns}>{weekColumnsEls}</div>
      <AppearanceTimeline selectedStorage={selectedStorage} />
    </div>
  );
};
